import React from "react";
import president from "../../img/highboard2025/president.jpg";
import cooCairo from "../../img/highboard2025/cairo.jpg";
// import viceCairo from "../../img/highboard2024/viceCairo.jpg";
import cooHelwan from "../../img/highboard2025/helwan.jpg";
// import viceGeneral from "../../img/highboard2024/viceGeneral.jpg";
import general from "../../img/highboard2025/general.jpg";

export default function Highboard() {
  return (
    <section className="section highboard">
      <div className="container">
        <h2>Highboard</h2>
        <div className="presedent">
          <img src={president} alt="" />
          <h3>Mariam Mahmoud</h3>
          <p>President</p>
        </div>

        <div className="coordinators">
          <div className="coordinator">
            <img src={cooCairo} alt="" />
            <h3>Abdullah Mohammed</h3>
            <p>Cairo coordinator</p>
          </div>
          {/* <div className="coordinator">
            <img src={viceCairo} alt="" />
            <h3></h3>
            <p>Vice cairo coordinator</p>
          </div> */}
          <div className="coordinator">
            <img src={cooHelwan} alt="" />
            <h3>Mazin Islam</h3>
            <p>Helwan coordinator</p>
          </div>
          <div className="coordinator">
            <img src={general} alt="" />
            <h3>Omar Ali</h3>
            <p>General coordinator</p>
          </div>
          {/* <div className="coordinator">
            <img src={viceGeneral} alt="" />
            <h3></h3>
            <p>Vice General coordinator</p>
          </div> */}
        </div>
      </div>
    </section>
  );
}
