import React from "react";
import MEhab from "../../img/board2025/Mariam Ehab.jpg";
import MYasser from "../../img/board2025/Mariam Yasser.jpg";

export default function SMM() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>SMM Committee</h2>
        <div className="supervisors">
          <div>
            <img src={MYasser} alt="" />
            <h3>Mariam Yasser</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={MEhab} alt="" />
            <h3>Mariem Ehab</h3>
            <p>Vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
