import React from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';

export default function BlogDetailsCard(props) {
    return (
        <div className="blog-details-card">
            <div className="title">
            <Link to={`/blogs/${props.id}`}>
                {props.title}
            </Link>
            <p className='date'>{dateFormat(Date.parse(props.date), "mmmm dS, yyyy")}</p>
            </div>
            <img src={`${axios.defaults.baseURL}/public/${props.cover}`} alt="" />
        </div>
    )
}
