import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import MUIDataTable from "mui-datatables";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import StatusHandler from "./components/StatusHandler";
import CommentHandler from "./components/CommentHandler";

const datafieldsname = {
  person_id: "ID",
  name: "Name",
  email: "Email",
  phone: "Phone",
  university: "University",
  collage: "Collage",
  university_level: "University Level",
  interview_time: "Assigned Interview time",
  date: "Actual Interview time",
  status: "Status",
};

const datafields = {
  person_id: "text",
  name: "text",
  email: "email",
  phone: "text",
  university: ["Cairo", "Ain Shams", "Helwan", "Other"],
  collage: "text",
  university_level: ["1", "2", "3", "4"],
  interview_time: "text",
  date: "text",
  status: ["Accepted", "Rejected"],
};

export default function NewStage16Interviews() {
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);

  const formatDate = (date) => {
    const newDateFormat = new Date(date);

    const year = newDateFormat.getFullYear();
    const month = String(newDateFormat.getMonth() + 1).padStart(2, "0");
    const day = String(newDateFormat.getDate()).padStart(2, "0");
    const hours = String(newDateFormat.getHours()).padStart(2, "0");
    const minutes = String(newDateFormat.getMinutes()).padStart(2, "0");

    const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateString;
  };

  // handle save button for status section
  const handleSaveStatus = (id, newStatus) => {
    axios
      .patch(`/api/events/newStage16/interviews/5-16/${id}`, {
        status: newStatus,
      })
      .then((res) => {
        console.log(res.data.message);
        console.log(id);
        swal({
          icon: "success",
          title: "Saved Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        axios
          .get(`/api/events/newStage16/interviews/5-16`)
          .then((res) => {
            console.log(res.data.message);
            setTableData(res.data.message);
          })
          .catch((error) => {
            toast.error("Failed to get data: " + error);
          });
      })
      .catch((error) => {
        toast.error("Failed to update data: " + error);
      });
  };

  // handle save button for comment section
  const handleSaveComment = (id, newComment) => {
    axios
      .patch(`/api/events/newStage16/interviews/5-16/${id}`, {
        comment: newComment,
      })
      .then((res) => {
        console.log(res.data.message);
        console.log(id);
        swal({
          icon: "success",
          title: "Saved Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        axios
          .get(`/api/events/newStage16/interviews/5-16`)
          .then((res) => {
            console.log(res.data.message);
            setTableData(res.data.message);
          })
          .catch((error) => {
            toast.error("Failed to get data: " + error);
          });
      })
      .catch((error) => {
        toast.error("Failed to update data: " + error);
      });
  };

  // get table data from server API
  useEffect(() => {
    axios
      .get(`/api/events/newStage16/interviews/5-16`)
      .then((res) => {
        console.log(res.data.message);

        const formattedTableData = res.data.message.map((element) => {
          const oldDate = element["date"];
          element["date"] = formatDate(oldDate);
          return element;
        });

        setTableData(formattedTableData);

        let temp = [];
        Object.keys(datafieldsname).map((key, val) => {
          return (temp = [
            ...temp,
            {
              name: `${key}`,
              label: `${datafieldsname[key]}`,
              options:
                typeof datafields[key] === "object"
                  ? { filterType: "Multiselect" }
                  : { filterType: "textField" },
            },
          ]);
        });

        // set columns of table
        setColumns([
          ...temp,
          {
            name: "Edit Status",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta) => (
                <StatusHandler id={tableMeta.rowData[0]} onSave={handleSaveStatus} />
              ),
            },
          },
          {
            name: "Add Comment",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta) => (
                <CommentHandler id={tableMeta.rowData[0]} onSave={handleSaveComment} />
              ),
            },
          },
          {
            name: "Send Mail",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Button
                    style={{ backgroundColor: "#700608" }}
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={() => {
                      const person_id = tableMeta.rowData[0];
                      const status = tableMeta.rowData[9];
                      console.log(person_id);

                      if (status === "Accepted") {
                        axios
                          .post(`/api/events/newStage16/sendMails`, {
                            person_id: person_id,
                          })
                          .then((res) => {
                            if (res.data.status) {
                              toast.success(`Mail Sent To ${tableMeta.rowData[1]}`);
                            } else {
                              toast.error(`Sent Recently To ${tableMeta.rowData[1]}`);
                            }
                          })
                          .catch((err) => {
                            toast.error(`Can't Send To ${tableMeta.rowData[1]}`);
                            console.log(err);
                          });
                      } else {
                        toast.error(
                          `Status of ${tableMeta.rowData[1]} is rejected, Can't send mail`
                        );
                      }
                    }}
                  >
                    Send Mail
                  </Button>
                );
              },
            },
          },
        ]);
      })
      .catch((error) => {
        toast.error("Failed to get data " + error);
      });
  }, []);

  const options = {
    selectableRows: "multiple",
    draggableColumns: { enabled: true },
    jumpToPage: true,
    hasIndex: true,
    downloadOptions: {
      filename: `NewStage16Interviews.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowsDelete: (rowsDeleted) => {
      console.log(rowsDeleted.data);
      const deleted_IDs = rowsDeleted.data; // IDs of table not person_id
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this field!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          for (var i = 0; i < deleted_IDs.length; i++) {
            console.log(tableData[deleted_IDs[i].dataIndex].person_id);
            axios
              .delete(
                `/api/events/newStage16/interviews/5-16/${
                  tableData[deleted_IDs[i].dataIndex].person_id
                }`
              )
              .then((res) => {
                if (res.data.status) {
                  toast.success("Deleted Successfully");
                } else {
                  console.log(res);
                  toast.error(`Failed with status ${res.data.status}`);
                }
              })
              .catch((err) => {
                toast.error("Can't delete");
                console.log(err);
              });
          }
        } else {
          swal("The entry is safe!").then((val) => window.location.reload());
        }
      });
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "flex",
            flexDirection: "row",
            flex: "initial",
          },
        },
      },
    });

  return (
    <>
      <Layout>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`New Stage 16 Interviews`}
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Layout>
    </>
  );
}
