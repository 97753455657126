import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";

export default function NotFound() {
  return (
    <div className="not-found">
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <h2>
        4<span>0</span>4
      </h2>
      <p>Sorry, Page you looking for is not found 😔</p>
      <Link to="/" className="btn btn-primary">
        Go To Homepage
      </Link>
    </div>
  );
}
