import React from "react";
import Navbar from "./Navbar";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Header(props) {
  const styling = {
    backgroundImage: `linear-gradient(to right, #700608, rgba(255, 255, 255, 0)),
      url("${props.backgroundimg}")`,
  };
  const maintext = props.maintext.replaceAll(" ", "");
  const [refernce, inView] = useInView({
    threshold: 0.35,
  });
  const animation = useAnimation();

  function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  }

  useEffect(() => {
    if (!inView) {
      animation.start({
        x: 0,
        transition: { type: "tween" },
      });
    } else {
      animation.start({
        x: "-100vw",
      });
    }
  }, [inView, animation]);
  
  return (
    <>
      <Navbar anim={props.home ? animation : 0} />
      <header className="header">
        <div className="header-container" style={styling} ref={refernce}>
          <div className="containerr">
            <div className="word-redirect" style={{ lineHeight: isFacebookApp() ? "1.1" : "1.4" }}>
              <p>▸</p>
              <p className="header-title">
                {maintext.length < 7 ? "|" : ""}
                {maintext}
                {maintext.length < 7 ? "|" : ""}
              </p>
              <p>◂</p>
            </div>
            <div className="word-main">
              {props.home && (
                <>
                  <div className="word-your">
                    <p>Y</p>
                    <p>O</p>
                    <p>U</p>
                    <p>R</p>
                  </div>
                  <div className="word-thinking">THINKING</div>
                </>
              )}
            </div>
            <div className="slogan">{props.slogan.toUpperCase()}</div>
          </div>
          <div className="main-image">
            {props.sideimg && <img src={`${props.sideimg}`} alt="sideimg" />}
          </div>
        </div>
      </header>
    </>
  );
}
