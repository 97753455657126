import React, { useState } from "react";
import Layout from "./components/Layout";
//import { useContext } from "react";
//import { UserContext } from "../context/UserContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { FaPlus } from "react-icons/fa";
import axios from "axios";

export default function AddCategory() {
  const [disableBtn, setDisbale] = useState(false);
  const [form, setForm] = useState({
    name:""
  });
  const history = useHistory();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisbale(true);
    axios
      .post(`/api/categories`, { ...form })
      .then((response) => {
        setDisbale(false);
        history.push("/dashboard/Categories");
      })
      .catch((err) => {
        setDisbale(false);
        toast.error("Error 😔");
      });
  };
  return (
    <Layout>
      <h2 className="title">Add New Category</h2>
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Name</label>
          <input type="text" name="name" value={form.name} required onChange={handleChange} />
        </div>
        <button className="btn btn-normal" disabled={disableBtn}>
          <FaPlus style={{ marginRight: 5 }} />
          Add Category
        </button>
      </form>
    </Layout>
  );
}
