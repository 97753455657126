import { useState, useEffect } from "react";
import QRCodeStyling from "qr-code-styling";
import logo from "./img/logo.png";

function useQrCode(data) {
  const [options, setOptions] = useState({
    width: 300,
    height: 300,
    data: String(data),
    image: logo,
    dotsOptions: {
      color: "#700608",
      type: "rounded",
    },
    cornersSquareOptions: {
      type: "extra-rounded",
    },
    cornersDotOptions: {
      type: "dot",
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 5,
    },
  });
  const [qrCode] = useState(new QRCodeStyling(options));

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(options);
  }, [qrCode, options]);

  const setQrCode = (newData) => {
    setOptions((options) => ({
      ...options,
      data: String(newData),
    }));
  };

  return [qrCode, setQrCode];
}

export { useQrCode };
