import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import swal from "sweetalert";
import SaveIcon from "@mui/icons-material/Save";

const StatusHandler = ({ id, onSave }) => {
  const [status, setStatus] = useState("");

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleSaveClick = () => {
    if (status === "") {
      swal({
        title: "",
        text: "Please select a Status",
        icon: "warning",
        dangerMode: true,
      });
      return;
    }
    onSave(id, status);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "170px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Edit Status</InputLabel>
          <Select
            labelId="select-label"
            id="select-status"
            label="Edit Status"
            value={status}
            onChange={handleStatus}
          >
            <MenuItem value={"Accepted"}>Accepted</MenuItem>
            <MenuItem value={"Rejected"}>Rejected</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <SaveIcon
        style={{
          backgroundColor: "green",
          borderRadius: "50%",
          fontSize: "37px",
          color: "white",
          padding: "8px 8px",
          cursor: "pointer"
        }}
        onClick={handleSaveClick}
      />
    </div>
  );
};

export default StatusHandler;
