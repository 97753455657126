import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const CommentHandler = ({ api, id, onSave }) => {
  const [comment, setComment] = useState("");

  useEffect(() => {
    axios
      .get(`${api}`)
      .then((res) => {
        console.log(res.data.message["comment"]);
        setComment(res.data.message["comment"]);
      })
      .catch(() => {
        toast.error("Failed to get comment of person id: " + id);
      });
  }, []);

  const handleComment = (event) => {
    console.log(event.target.value);
    setComment(event.target.value);
  };

  const handleSaveClick = () => {
    onSave(id, comment);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "265px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        id="outlined-multiline-static"
        multiline
        rows={3}
        onChange={handleComment}
        defaultValue={comment}
        inputProps={{ style: { fontSize: 13 } }}
        sx={{ width: 290 }}
        style={{
          marginRight: "10px",
        }}
      />
      <SaveIcon
        style={{
          backgroundColor: "green",
          borderRadius: "50%",
          fontSize: "37px",
          color: "white",
          padding: "8px 8px",
          cursor: "pointer",
        }}
        onClick={handleSaveClick}
      />
    </div>
  );
};

export default CommentHandler;
