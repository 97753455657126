import React from "react";
import Jonathan from "../../img/board2025/Johnathan Eskander.jpg";
import Basel from "../../img/board2025/Basel Hamdi.jpg";

export default function Technical() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>Technical Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Jonathan} alt="" />
            <h3>Johnathan Eskander</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={Basel} alt="" />
            <h3>Basel Hamdi</h3>
            <p>Vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
