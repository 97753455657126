import React, { useState } from "react";
import Layout from "./components/Layout";
import axios from "axios";
import { toast } from "react-toastify";

export default function Database() {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const getData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post("/api/utilities/query", {
        query: query,
      });
      setResult(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("There is an error happend!");
    }
  };
  return (
    <Layout>
      <div className="database">
        <h1>Database</h1>
        <form onSubmit={getData}>
          <div className="input-group">
            <textarea
              rows="10"
              placeholder="SQL Query"
              onChange={(e) => setQuery(e.target.value)}
            ></textarea>
          </div>
          <button className="btn btn-normal btn-sm" disabled={loading}>
            Execute
          </button>
        </form>
        <div className="sql-result">
          <pre>{result && JSON.stringify(result, null, 2).replace(/\\n/g, "\n\t")}</pre>
        </div>
        <div className="instructions">
          <h2>Instructions</h2>
          <p>
            You can get the create statement of a table by using the following query:
            <code>SHOW CREATE TABLE table_name</code>
          </p>
          <p>
            You can get the list of all tables in the database by using the following query:
            <code>SHOW TABLES</code>
          </p>
          <p>
            You can get the list of all columns in a table by using the following query:
            <code>SHOW COLUMNS FROM table_name</code>
          </p>
        </div>
      </div>
    </Layout>
  );
}
