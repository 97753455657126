import React, { useState, useEffect, useRef } from "react";
import EventLayout from "../../components/EventLayout";
import MirageZoneCover from "../../img/events/MirageZone-Cover.jpg";
import swal from "sweetalert";
import axios from "axios";

import { useQrCode } from "../../QrCode";
import { Stepper } from "react-form-stepper";

export default function MirageZone() {
  const headerprops = {
    maintext: "Mirage Zone",
    backgroundimg: MirageZoneCover,
    slogan: "Mirage Zone IS UNDER THE WAY!!",
  };

  const [disabled, setDisabled] = useState(false);
  const [engineer, setEngineer] = useState(false);
  const [qrCode, setQrCode] = useQrCode(null);
  const [showQR, setShowQR] = useState(false);
  const ref = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);

  const [membersData, setMembersData] = useState([]);
  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    document.title = `180Daraga | Mirage Zone`;
  });

  // Handle changes save data of members
  const handleChange = (e, index) => {
    index = index - 1;
    if (e.target.name === "email") {
      if (e.target.value.indexOf("‏") !== -1) {
        e.target.value = e.target.value.slice(0, e.target.value.lastIndexOf("‏"));
      }
    }
    if (e.target.name === "team_name") {
      setTeamName(e.target.value);
    } else {
      // Add level 5 if faculty is engineering
      if (e.target.name === "faculty") {
        setEngineer(e.target.value === "engineering");
      }

      if (e.target.name === "level") {
        e.target.value = +e.target.value;
      }
      const updatedMembersData = [...membersData];
      updatedMembersData[index] = {
        ...updatedMembersData[index],
        [e.target.name]: e.target.value,
      };
      setMembersData(updatedMembersData);
    }
  };

  const inputfield = (id, val, type, label, style = false) => {
    return (
      <div
        className="input-group"
        key={val}
        style={{ margin: style ? "0 10px 15px 0" : "0 0 15px 0" }}
      >
        <label htmlFor={label}>{label.charAt(0).toUpperCase() + label.slice(1)}</label>
        <input type={type} name={label} id={label} onChange={(e) => handleChange(e, id)} required />
      </div>
    );
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setDisabled(true);

    membersData.forEach((member) => {
      member.level = +member.level;
    });

    if ((await validateData()) === 0) {
      return;
    }

    axios
      .post(`https://apimiragezone24.180daraga.com/team`, {
        name: teamName,
        teamMembers: membersData,
      })
      .then((response) => {
        if (response.data.status) {
          setShowQR(true);
          setQrCode(response.data.message);
          qrCode.append(ref.current);
        } else {
          swal({
            title: "Oops!",
            text: "Something went wrong",
            icon: "error",
          });
          setDisabled(false);
        }
      })
      .catch((err) => {
        swal({
          title: "Oops!",
          text: "Something went wrong. Please contact us via Facebook for assistance.",
          icon: "error",
        });
        setDisabled(false);
      });
  };


  let members = ["First Member", "Second Member", "Third Member"];

  // Next steps
  const nextStep = async (e) => {
    e.preventDefault();
    if ((await validateData()) === 0) {
      return;
    }
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Previous Steps
  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Validate Data Function with every Next button
  const validateData = async () => {
    var phoneNo = /^\d{11}$/;
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    // Validate team name
    if (currentStep === 0) {
      if (!teamName) {
        swal({
          title: "Error 😔",
          text: "Please enter a team name",
          icon: "error",
        });
        return 0;
      }

      try {
        await axios.post(`https://apimiragezone24.180daraga.com/team/checkteam`, {
          name: teamName,
        });
      } catch (error) {
        if (error.response.data.message) {
          swal({
            title: "Oops!",
            text: error.response.data.message,
            icon: "error",
          });
        } else {
          swal({
            title: "Oops!",
            text: "Something went wrong. Please contact us via Facebook for assistance.",
            icon: "error",
          });
        }
        return 0;
      }
    }
    // Validation logic for team members
    else if (currentStep > 0 && currentStep <= 3) {
        const currentMemberIndex = currentStep - 1;
        const currentMemberData = membersData[currentMemberIndex];

        // Check if any required fields are empty
        if (!currentMemberData.name || !currentMemberData.email || !currentMemberData.phone || !currentMemberData.faculty || !currentMemberData.level) {
          swal({
            title: "Error 😔",
            text: "Please fill all the fields",
            icon: "error",
          });
          return 0;
        }

        // Check if phone number is valid
        if (!currentMemberData.phone.match(phoneNo)) {
          swal({
            title: "Error 😔",
            text: "Please enter a valid phone number\nIn likes of 01XXXXXXXXX",
            icon: "error",
          });
          return 0;
        }

        // Check if email is valid
        if (!currentMemberData.email.match(emailRegex)) {
          swal({
            title: "Error 😔",
            text: "Please enter a valid email",
            icon: "error",
          });
          return 0;
        }

        // Check for duplicate entries while registering emails and phones.
        for (let i = 0; i < membersData.length; i++) {
          if (i !== currentMemberIndex) {
            const member = membersData[i];
            if (member.email === currentMemberData.email || member.phone === currentMemberData.phone) {
              swal({
                title: "Oops!",
                text: "You have just entered this member data before.",
                icon: "error",
              });
              return 0;
            }
          }
        }

        // Check for duplicates in the database as registered before
        try {
          await axios.post(`https://apimiragezone24.180daraga.com/team/checkmember`, {
            email: currentMemberData.email,
            phone: currentMemberData.phone
          });
        } catch (error) {
          if (error.response.data.message) {
            swal({
              title: "Oops!",
              text: error.response.data.message,
              icon: "error",
            });
          } else {
            swal({
              title: "Oops!",
              text: "Something went wrong. Please contact us via Facebook for assistance.",
              icon: "error",
            });
          }
          return 0;
        }

        // If all validations pass, return 1 indicating success
        return 1;
      }
    };

    return (
      <>
        {!showQR && (
          <EventLayout headerconfig={headerprops}>
            <div className="cautions">
              <h3>Caution</h3>

              <p style={{ marginTop: "20px" }}>
                Be sure to check your email after submitting the form!
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <Stepper
                steps={[
                  { label: "Team Name" },
                  { label: "First Member" },
                  { label: "Second Member" },
                  { label: "Third Member" },
                ]}
                activeStep={currentStep}
              />
              <div
                className="input-group"
                key="name"
                style={{ display: currentStep === 0 ? "block" : "none" }}
              >
                <h3 htmlFor="team_name" style={{ marginBottom: 15 }}>
                  Team Name
                </h3>
                <input
                  type="text"
                  name="team_name"
                  id="team_name"
                  onChange={(e) => handleChange(e)}
                  required
                />
                <button onClick={nextStep} className="btn btn-normal" style={{ marginTop: 15 }}>
                  Next
                </button>
              </div>
              {members.map((el, val) => {
                const id = val + 1;
                return (
                  <>
                    <div
                      className="step-group"
                      id={id}
                      style={{ width: "100%", display: id === currentStep ? "block" : "none" }}
                    >
                      <div className="input-group">
                        <h3>{el}</h3>
                      </div>
                      {inputfield(id, val++, "text", "name")}
                      <div style={{ display: "flex", width: "100%" }}>
                        {inputfield(id, val++, "text", "phone", true)}
                        {inputfield(id, val++, "email", "email")}
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <div className="input-group" style={{ margin: "0 10px 15px 0" }}>
                          <label htmlFor={`faculty`}>Faculty</label>
                          <select
                            name={`faculty`}
                            id={`faculty`}
                            onChange={(e) => handleChange(e, id)}
                            required
                          >
                            <option value={""}>Select an option:</option>
                            <option value="cs" key={val++}>
                              CS - Helwan
                            </option>
                            <option value="engineering" key={val++}>
                              Engineering - Helwan
                            </option>
                            <option value="science" key={val++}>
                              Science - Helwan
                            </option>
                          </select>
                        </div>
                        <div className="input-group">
                          <label htmlFor={`level`}>Level</label>
                          <select
                            name={`level`}
                            id={`level`}
                            onChange={(e) => handleChange(e, id)}
                            required
                          >
                            <option value={""}>Select an option:</option>
                            <option value="1" key={val++}>
                              1
                            </option>
                            <option value="2" key={val++}>
                              2
                            </option>
                            <option value="3" key={val++}>
                              3
                            </option>
                            <option value="4" key={val++}>
                              4
                            </option>
                            {
                              engineer &&
                              <option value="5" key={val++}>
                                5
                              </option>
                            }
                          </select>
                        </div>
                      </div>
                      <div className="buttons">
                        {id < 3 && (
                          <button
                            onClick={nextStep}
                            className="btn btn-normal"
                            style={{ marginBottom: 15 }}
                          >
                            Next
                          </button>
                        )}
                        {id === 3 && (
                          <button
                            className="btn btn-normal"
                            type="submit"
                            disabled={disabled}
                            style={{ marginBottom: 15 }}
                          >
                            Submit
                          </button>
                        )}
                        {id > 0 && (
                          <button onClick={prevStep} className="btn btn-normal">
                            Previous
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </form>
          </EventLayout>
        )}
        {showQR && (
          <EventLayout headerconfig={headerprops}>
            <div className="QRcodeContainer" style={{ textAlign: "center", margin: "30px" }}>
              <div className="QRcodeContainerText">
                <h1 style={{ fontSize: "40px", margin: "20px" }}>Thank You!</h1>
                <p style={{ fontSize: "20px", margin: "20px" }}>Wait to see you at the event. 🎯⏳</p>
                <p style={{ fontSize: "20px", margin: "20px" }}>
                  Please keep this QRcode somewhere safe, as your team will need it to attend the event
                </p>
              </div>
              <div ref={ref} />
            </div>
          </EventLayout>
        )}
      </>
    );
  }
