import React from 'react'
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
export default function Result({ status, question }) {
    return (
        <div style={{ direction: "rtl", display: "flex", justifyContent: "center", alignItems: "center", padding: "20px", flexDirection: "column", gap: "20px" }}>
            {
                status === "A" ? <AiFillCheckCircle size={80} color='green' /> : <AiFillCloseCircle size={80} color='red' />
            }
            <h2>
                {question.title}
            </h2>
            <p style={{ fontSize: "22px" }}>
                الاجابه الصحيحه : {question.choices[question.rightChoiceIndex]}
            </p>
            <Link to="/" className="btn btn-primary">
                Go To Homepage
            </Link>
        </div>
    )
}
