import React from "react";
import { motion } from "framer-motion";

export default function Layout(props) {
  return (
    <motion.div initial={{ opacity: 0.8 }} animate={{ opacity: 1 }} className={props.className}>
      {props.children}
    </motion.div>
  );
}
