import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sass/main.scss";
import { UserProvider } from "./context/UserContext";
import axios from "axios";

// axios.defaults.baseURL = "http://localhost:5550";
axios.defaults.baseURL = "https://api.180daraga.com";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("jwt")}`;

ReactDOM.render(
  <React.StrictMode>
    <Router forceRefresh={true}>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
