import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img1 from "../../img/1.jpeg";
import img2 from "../../img/2.jpg";
import img3 from "../../img/3.jpeg";
import img5 from "../../img/5.jpeg";
import img6 from "../../img/6.jpg";
import img7 from "../../img/9.jpeg";
import img8 from "../../img/8.jpeg";
import Stage15 from "../../img/stage15.jpg"

export default function Gallary() {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        transition: {
          duration: 0.3,
        },
      });
    } else {
      animation.start({
        scale: 0,
      });
    }
  }, [inView, animation]);

  return (
    <section ref={ref} className="gallary">
      <motion.img animate={animation} src={img1} alt="gallary 1" />
      <motion.img animate={animation} src={img2} alt="gallary 2" />
      <motion.img animate={animation} src={img3} alt="gallary 3" />
      <motion.img animate={animation} src={Stage15} alt="gallary 4" />
      <motion.img animate={animation} src={img5} alt="gallary 5" />
      <motion.img animate={animation} src={img6} alt="gallary 6" />
      <motion.img animate={animation} src={img7} alt="gallary 7" />
      <motion.img animate={animation} src={img8} alt="gallary 8" />
    </section>
  );
}
