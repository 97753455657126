import React from "react";
//import Navbar from "../components/Navbar";
import Footer from "./Footer";
import Header from "./Header";
export default function EventLayout(props) {
  return (
    <div className="event">
      <Header {...props.headerconfig} />

      {props.closed ? (
        <div className="closed">
          <h2>Unfortunately, This Form is closed</h2>
          <p>If you have any questions, please contact us on our page in facebook</p>
        </div>
      ) : (
        props.children
      )}

      <Footer />
    </div>
  );
}
