import Layout from "./components/Layout";
import React, { useState, useContext } from "react";
import axios from "axios";
import swal from "sweetalert";
import { UserContext } from "../context/UserContext";

export default function ChangePassword() {
  const [password, setPassword] = useState();
  const { id, fname } = useContext(UserContext);

  const UpdatePass = () => {
    swal({
      title: "Are you sure you want to change the password?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((update) => {
      if (update) {
        axios
          .patch(`/api/users/updatepass`, {
            id: id,
            password: password,
          })
          .then((response) => {
            swal("Password Updated!", `New Password: ${password}`, "success");
          })
          .catch((err) => {
            swal("Error", "Password not updated", "error");
          });
      }
    });
  };

  return (
    <Layout>
      <form className="form">
        <div className="welcome">
          <h2>Hello {fname}</h2>
        </div>
        <div className="input-group">
          <label>New Password</label>
          <input
            type="password"
            placeholder="please enter your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <button
            className="btn btn-normal"
            onClick={(e) => {
              e.preventDefault();
              UpdatePass();
            }}
          >
            Change
          </button>
        </div>
      </form>
    </Layout>
  );
}
