import React from "react";
import Alaa from "../../img/board2025/Alaa Atef.jpg";
import Noureen from "../../img/board2025/Noureen Saad.jpg";

export default function Graphic() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>Graphic Design Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Alaa} alt="" />
            <h3>Alaa Atef</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={Noureen} alt="" />
            <h3>Noureen Saad</h3>
            <p>committee director</p>
          </div>
        </div>
      </div>
    </section>
  );
}
