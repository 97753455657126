import Layout from './components/Layout';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';


const datafields = {
    id: "text",
    sender_email: "email",
    recipient_email: "email",
    subject: "text",
    error: "text",
    status: ["Sent", "Failed"],
    sentAt: "text",
};

const datafieldsname = {
    id: "ID",
    sender_email: "Sender Email",
    recipient_email: "Recipient Email",
    subject: "Subject",
    error: "Error",
    status: "Status",
    sentAt: "Sent At",
}


export default function MailsLogs() {

    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords,setTotalRecords] = useState(0);
    const [sort,setSort] = useState(null);
    const [sortby,setSortBy] = useState(null);
    const [filter,setFilter] = useState(null);



    const buildQuery = (filterlist)=>{
        let query = {};
        columns.forEach((column, index) => {
            if (filterlist[index].length > 0) {
                if (column.name === "status") {
                    
                    query[column.name] = filterlist[index];
                } else if (column.name === "error") {
                    if("no error✅".includes(filterlist[index][0].toLowerCase()))
                        query[column.name] = null;
                    else
                        query[column.name] = filterlist[index][0];
                }
                else {
                    query[column.name] = filterlist[index][0];
                }
            }
        })
        console.log(query);
        return query;
    };



    const formatError= (error) => {
        return Object.entries(error).map(([key, value]) => {
            return `${key}: ${value}`;
        }).join("\n");
    }

    const fetchData = useCallback(async (page=0, limit=10,sort = null,sortby = null,filter=null) => {
        try{
            const response = await axios.get('/api/mailslogs',{
                params: {
                    page: page + 1,
                    limit: limit,
                    sort,
                    sortby,
                    ...filter,
                },
            });
            const formattedData = response.data.message.map((item) => ({
                ...item,
                // error: item.error ? formatError(JSON.parse(item.error)) : "No Error✅",
                error: item.error ? item.error : "No Error✅",
                sentAt: new Date(item.sentAt).toLocaleString(),
            }));
            // console.log(formattedData);
            
            setTableData(formattedData);
            setTotalRecords(response.data.totalrecords);
        }catch(error){
            
            toast.error(error.response.data.message);
        }

    },[])

    useEffect(()=>{
        fetchData(currentPage,rowsPerPage,sort,sortby,filter);
    },[fetchData,currentPage,rowsPerPage,sort,sortby,filter]);



    const columns = useMemo(()=>{
        const datacolumns = Object.keys(datafields).map((key) => {
            return {
                name: key,
                label: datafieldsname[key],
                options: {
                    filter: true,
                    sort: true,
                    filterType: typeof datafields[key] === "object" ? "multiselect" : "textField",
                    ...(key === "status" && {
                        filterOptions: {
                            names: datafields[key],
                    
                        },
                    })
                },
                
            };
        });
        return [
            ...datacolumns
        ];
    },[])

    const options = useMemo(()=>{
        return{
        serverSide: true,
        count: totalRecords,
        page: currentPage,
        rowsPerPage: rowsPerPage,
        search:false,
        onTableChange: (action, tableState)=>{
            // console.log(action,tableState);
            switch(action){
                case "changePage":
                    setCurrentPage(tableState.page);
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage);
                    break;
                case "sort":
                    // console.log(tableState.sortOrder);
                    setSort(tableState.sortOrder.name);
                    setSortBy(tableState.sortOrder.direction);
                    break;
                case"filterChange":
                    // console.log(tableState.filterList);
                    console.log(buildQuery(tableState.filterList));
                    setFilter(buildQuery(tableState.filterList));
                    setCurrentPage(0);
                    break;
                default:
                    break;
            }
        },
        selectableRows: "multiple",
        draggableColumns: { enabled: true },
        jumpToPage: true,
        hasIndex: true,
        downloadOptions: {
            filename: `MailsLogs.csv`,
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
    onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    
    }} ,[totalRecords,currentPage,rowsPerPage]);

   

    const getMuiTheme = useMemo(()=>createTheme({
        overrides: {
            MUIDataTableToolbar: {
                actions: {
                    display: "flex",
                    flexDirection: "row",
                    flex: "initial",
                },
            },
        },
    }),[]);

    return (
        <>
            <Layout>
                <ThemeProvider theme={getMuiTheme}>
                    <MUIDataTable
                        title={"Mails Logs"}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </Layout>
        </>
        
        
    );
}