import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import BlogCard from "../components/BlogCard";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Link } from "react-router-dom";
export default function BlogsMain() {
    const [categories, setCategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [search, setSearch] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState({
        search: "",
        category: "All"
    })

    useEffect(() => {
        document.title = "180Daraga | Blogs";
        axios
            .get(`/api/categories`)
            .then((response) => {
                setCategories(response.data.message);
                console.log(response.data.message);
                axios
                    .get(`/api/blogs/all/published`)
                    .then((res) => {
                        console.log(res.data.message);
                        setBlogs(res.data.message);
                        setSearch(res.data.message);
                        // setSlider(res.data.message[0]);
                        setLoading(false);
                    })
                    .catch((err) => toast.error("Something wrong happend!"));
            })
            .catch((err) => toast.error("Something wrong happend!"));
    }, []);
    const handleSearch = (e) => {
        setQuery({ ...query, ["search"]: e.target.value });
        const data = search.filter((el) => {
            if ((el.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || el.body.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) &&
                (query.category === "All" || el.categories.indexOf(query.category) !== -1))
                return true;
            else
                return false;
        })
        setBlogs(data);
    }

    const handleSelect = (e) => {
        setQuery({ ...query, ["category"]: e.target.value });
        const data = search.filter((el) => {
            if ((el.title.toLowerCase().indexOf(query.search.toLowerCase()) !== -1 || el.body.toLowerCase().indexOf(query.search.toLowerCase()) !== -1) &&
                (e.target.value === "All" || el.categories.indexOf(e.target.value) !== -1))
                return true;
            else
                return false;
        })
        setBlogs(data);
    }

    return (
        <Layout className="blogs_main">
            <Navbar />
            {
                loading && (
                    <div class="card__image" style={{height:"50vh"}}></div>
                )
            }
            {
                !loading && (
                    <div className="slide-container">
                    <Zoom>
                        {search.map((el, index) => (
                            <div className="slide" key={index}>
                                <img src={`${axios.defaults.baseURL}/public/${el.cover}`} />
                                <div className="title">
                                    <Link to={`/blogs/${el.id}`}>
                                        {el.title}
                                    </Link>
                                </div>
                            </div>
                            )
                        )
                        }
                        
                    </Zoom>
                </div>
                )
            }
            <div className="container">
                <div className="content">
                    <div className="filter_container">
                        <div class="search-box">
                            <FaSearch className="search_icon" />
                            <input type="text" placeholder="Search..." onChange={handleSearch} />
                        </div>
                        <div className="categories">
                            <select name="categories" required onChange={handleSelect}>
                                <option value="" defaultValue>
                                    All
                                </option>
                                {
                                    categories.map((el) => <option value={el.name}>{el.name}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <div className="blogs">
                        {
                            loading && (
                                Array(3).fill(0).map((el) => {
                                    return (
                                        <div class="card">
                                            <div class="card__image"></div>
                                            <div class="card__content">
                                                <h2></h2>
                                                <p></p>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                            )
                        }
                        {
                            (!loading && blogs.length !== 0) && (
                                <>
                                    {
                                        blogs.map((el) => {
                                            return (
                                                <BlogCard
                                                    cover={el.cover}
                                                    title={el.title}
                                                    body={el.plain_text}
                                                    date={el.date}
                                                    id={el.id}
                                                />
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                    </div>
                    {
                        (!loading && blogs.length === 0) && (
                            <div className="no-results">
                                <h1>Oops! No results found</h1>
                                <p>
                                    We couldn't find what you are looking for 😔
                                </p>
                                <p>
                                    Please try again
                                </p>
                            </div>
                        )
                    }
                </div>
            </div>
            <Footer />
        </Layout>
    );
}
