import React, { useEffect } from "react";
import About from "../components/sections/About";
import Header from "../components/Header";
//import Latest from "../components/sections/Latest";
import Counters from "../components/sections/Counters";
import Gallary from "../components/sections/Gallary";
import Sponsers from "../components/sections/Sponsers";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import backgroundimg from "../img/stage17.jpeg";
import sideimg from "../img/logo.png";
export default function Home() {
  useEffect(() => {
    document.title = "180Daraga | Home";
  }, []);

  const headerprops = {
    maintext: "REDIRECT",
    backgroundimg: backgroundimg,
    sideimg: sideimg,
    home: true,
    slogan: "The official 180 Daraga website!",
  };

  return (
    <Layout className="home">
      <Header {...headerprops} />
      <About />
      {/* <Latest /> */}
      <Counters />
      <Gallary />
      <Sponsers />
      <Footer />
    </Layout>
  );
}
