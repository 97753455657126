import React, { useEffect, useState, useMemo, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import Layout from "./components/Layout";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import swal from "sweetalert";
import StatusHandler from "./components/StatusHandler";
import CommentHandler from "./components/CommentHandler";

const datafields = {
    person_id: "text",
    name: "text",
    university: ["Cairo", "Ain Shams", "Helwan", "Other"],
    university_name: "text",
    college: "text",
    university_level: ["1", "2", "3", "4"],
    first_committee: ["PR/FR", "Graphics", "Social Media Marketing", "Video", "Technical"],
    second_committee: ["PR/FR","Graphics", "Social Media Marketing", "Video", "Technical"],
    interview_time: "text",
    date: "text",
    status: ["Accepted", "Rejected"],
    comment: "text",
};

const datafieldsname = {
    person_id: "ID",
    name: "Name",
    university: "University",
    university_name: "Other University Name",
    college: "College",
    university_level: "Level",
    first_committee: "First Committee",
    second_committee: "Secondd Committee",
    interview_time: "Assigned Interview Time",
    date: "Attended Interview Time",
    status: "Status",
    comment: "Comment",
};

export default function Recruitment24Interviews() {
    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const formatDate = (date) => {
        const newDate = new Date(date);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayofWeek = days[newDate.getDay()];
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, "0");
        const day = String(newDate.getDate()).padStart(2, "0");
        const hours = String(newDate.getHours()).padStart(2, "0");
        const minutes = String(newDate.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedDateString = `${dayofWeek} ${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
        return formattedDateString;
    }

    const fetchData = useCallback(async (page = 0, limit = 10) => {
        try {
            const res = await axios.get("/api/events/recruitment24/interviews", {
                params: {
                    page: page + 1,
                    limit,
                },
            });

            const formattedData = res.data.message.map((element) => ({
                ...element,
                date: formatDate(element.date),
            }));

            setTableData(formattedData);
            setTotalRecords(res.data.total);
        } catch (err) {
            toast.error("Failed to get Data 😔");
        }
    }, []);

    useEffect(() => {
        fetchData(currentPage, rowsPerPage);
    }, [fetchData, currentPage, rowsPerPage]);

    const handleSaveStatus = useCallback(async (id, newStatus) => {
        try {
            await axios.patch(`/api/events/recruitment24/interviews/${id}`, { status: newStatus });
            swal({
                icon: "success",
                title: "Saved Successfully",
                showConfirmButton: false,
                timer: 1500,
            });
            fetchData(currentPage, rowsPerPage);
        } catch (err) {
            toast.error("Failed to Update Status 😔");
        }
    }, [fetchData, currentPage, rowsPerPage]);

    const handleSaveComment = useCallback(async (id, newComment) => {
        try {
            await axios.patch(`/api/events/recruitment24/interviews/${id}`, { person_id: id, comment: newComment });
            swal({
                title: "Success",
                text: "Saved Successfully",
                icon: "success",
                timer: 1500
            });
            fetchData(currentPage, rowsPerPage);
        } catch (err) {
            toast.error("Failed to Update Comment 😔");
        }
    }, [fetchData, currentPage, rowsPerPage]);

    const handleRowsDelete = useCallback(async (rowsDeleted) => {
        const rows = rowsDeleted.data;
        console.log(rows);
        const personIdsToDelete = rows.map(row => tableData[row].person_id);
        const willDelete = await swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this field!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (willDelete) {
            try {
                await Promise.all(personIdsToDelete.map(id => axios.delete(`/api/events/recruitment24/interviews/${id}`)));
                toast.success("Selected entries deleted successfully.");
                fetchData(currentPage, rowsPerPage);
            } catch (err) {
                toast.error("Failed to delete some entries 😔");
                fetchData(currentPage, rowsPerPage);
            }
        } else {
            swal({
                text: "Entry is safe",
                timer: 1500
            });
        }
    }, [tableData, fetchData, currentPage, rowsPerPage]);

    const handleRowsEmails = useCallback(async (rowsSelected) => {
        const rows = rowsSelected.data;
        console.log(rows);
        const personIds = rows.map(row => tableData[row].person_id);
        console.log(personIds);
        const acceptedIds = personIds.filter(id => tableData.find(item => item.person_id === id).status === "Accepted");
        console.log(acceptedIds)
        try {
            await Promise.all(acceptedIds.map(id => axios.post("/api/events/recruitment24/sendmail/interview", { person_id: id })));
            toast.success("Mails sent successfully.");
            
        } catch (err) {
            toast.error("Failed to send some mails 😔");
        }
    }, [tableData]);

    const sendMail = useCallback(async (id, status) => {
        if (status === "Accepted") {
            try {
                await axios.post("/api/events/recruitment24/sendmail/interview", { person_id: id });
                toast.success("Mail Sent Successfully.");
            } catch (err) {
                toast.error("Failed to send Mail 😔");
            }
        } else {
            toast.error("Can't send Mail To Rejected People");
        }
    }, []);

    const columns = useMemo(() => {
        const dataColumnOrder = [
            'person_id',
            'name',
            'university',
            'university_name',
            'college',
            'university_level',
            'first_committee',
            'second_committee',
            'interview_time',
            'date',
            'status'
        ];

        const dataColumns = dataColumnOrder.map((key) => ({
            name: key,
            label: datafieldsname[key],
            options:
                typeof datafields[key] === "object"
                    ? { filterType: "multiselect" }
                    : { filterType: "textField" },
        }));

        const editStatusColumn = {
            name: "Edit Status",
            label: "Edit Status",
            options: {
                sort: false,
                filter: false,
                empty: true,
                customBodyRender: (value, tableMeta) => (
                    <StatusHandler id={tableMeta.rowData[0]} onSave={handleSaveStatus} />
                )
            }
        };

        const addCommentColumn = {
            name: "Add Comment",
            label: "Add Comment",
            options: {
                sort: false,
                filter: false,
                empty: true,
                customBodyRender: (value, tableMeta) => (
                    <CommentHandler api={`/api/events/recruitment24/getperson/${tableMeta.rowData[0]}`}
                        id={tableMeta.rowData[0]} onSave={handleSaveComment} />
                )
            }
        };

        const sendMailColumn = {
            name: "Send Mail",
            label: "Send Mail",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta) => (
                    <Button
                        style={{ backgroundColor: "#700608" }}
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => {
                            const person_id = tableMeta.rowData[0];
                            const status = tableMeta.rowData[dataColumnOrder.length - 1];
                            sendMail(person_id, status);
                        }}
                    >
                        Send Mail
                    </Button>
                )
            }
        };

        return [
            ...dataColumns,
            editStatusColumn,
            addCommentColumn,
            sendMailColumn
        ];
    }, [handleSaveStatus, handleSaveComment, sendMail]);

    const options = useMemo(() => ({
        selectableRows: "multiple",
        // serverSide: true,
        count: totalRecords,
        page: currentPage,
        rowsPerPage: rowsPerPage,
        onTableChange: (action, tableState) => {
            if (action === 'changePage') {
                setCurrentPage(tableState.page);
            }
            if (action === 'changeRowsPerPage') {
                setRowsPerPage(tableState.rowsPerPage);
            }
        },
        draggableColumns: { enabled: true },
        jumpToPage: true,
        hasIndex: true,
        downloadOptions: {
            filename: "Recruitment24Interviews.csv",
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(data);
        },
        onRowsDelete: handleRowsDelete,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Mails Button */}
                <Button
                    style={{ backgroundColor: "#700608", marginRight: "50px" }}
                    variant="contained"
                    onClick={() => {
                        const rowsToEmail = selectedRows.data.map(d => d.dataIndex);
                        handleRowsEmails({ data: rowsToEmail });
                        setSelectedRows([]);
                    }}
                    startIcon={<ArrowOutwardOutlinedIcon />}
                >
                    Send Mails
                </Button>

                {/* Delete Button */}
                <IconButton
                    aria-label="Delete"
                    style={{ color: "#700608", marginRight: "50px" }}
                    onClick={() => {
                        const rowsToDelete = selectedRows.data.map(d => d.index);
                        handleRowsDelete({ data: rowsToDelete });
                        setSelectedRows([]);
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        ),
    }), [totalRecords, currentPage, rowsPerPage, handleRowsDelete, handleRowsEmails]);

    const getMuiTheme = useMemo(() => createTheme({
        components: {
            MuiDataTableToolbar: {
                styleOverrides: {
                    actions: {
                        display: "flex",
                        flexDirection: "row",
                        flex: "initial",
                    },
                },
            },
        },
    }), []);

    return (
        <Layout>
            <ThemeProvider theme={getMuiTheme}>
                <MUIDataTable
                    title={"Recruitment 24 First Interview"}
                    data={tableData}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </Layout>
    )
}