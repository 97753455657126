import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import Layout from "./components/Layout";
import { Button } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import swal from "sweetalert";
import StatusHandler from "./components/StatusHandler";
import CommentHandler from "./components/CommentHandler";


const datafields = {
  person_id: "text",
  name: "text",
  email: "email",
  phone: "text",
  university: ["Cairo", "Ain Shams", "Helwan", "Other"],
  collage: "text",
  university_level: ["1", "2", "3", "4"],
  interview_time: "text",
  date: "text",
  status: ["Accepted", "Rejected"],
};

const datafieldsname = {
  person_id: "ID",
  name: "Name",
  email: "Email",
  phone: "Phone",
  university: "University",
  collage: "College",
  university_level: "University Level",
  interview_time: "Assigned Interview time",
  date: "Attended Interview time",
  status: "Status",
};

export default function NewStage17Interviews() {

  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);

  const formatDate = (date) => {
    const newDate = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayofWeek = days[newDate.getDay()];
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");
    const hours = String(newDate.getHours()).padStart(2, "0");
    const minutes = String(newDate.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? 'pm' : 'AM';
    const formattedhours = hours % 12 || 12
    const formattedDateString = `${dayofWeek} ${day}-${month}-${year} ${formattedhours}:${minutes} ${ampm}`;
    return formattedDateString;
  }

  const fetchData = async () => {  //get data
    try {
      const res = await axios.get("/api/event/newstage17/interviews");

      const formateddata = res.data.message.map((element) => {
        element["date"] = formatDate(element["date"]);
        return element;
      });
      setTableData(formateddata);
    } catch (err) {
      toast.error("Failed to get Data 😔");
    }
  }

  const handleSaveStatus = (id, newStatus) => {
    axios
      .patch(`/api/event/newstage17/interviews/${id}`, {
        status: newStatus
      })
      .then((res) => {
        swal({
          icon: "success",
          title: "Saved Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        fetchData()

      })
      .catch((err) => {
        toast.error("Failed to Update Status 😔");
      })
  }

  const handleSaveComment = (id, newComment) => {
    axios
      .patch(`/api/event/newstage17/interviews/${id}`, {
        person_id: id,
        comment: newComment
      })
      .then((res) => {
        swal({
          title: "Success",
          text: "Saved Successfully",
          icon: "success",
          timer: 1500
        });

        fetchData()

      })
      .catch((err) => {
        toast.error("Failed to Update Comment 😔");
      })
  }


  const handleRowsDelete = async (rowsDeleted) => {  //delete rows
    const rows = rowsDeleted.data;

    const updateddata = tableData.filter((val, index) => {
      return !rows.includes(index);
    })
    setTableData(updateddata);
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this field!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    if (willDelete) {
      for (let index = 0; index < rows.length; index++) {
        const personId = tableData[rows[index]].person_id;

        axios
          .delete(`/api/event/newstage17/interviews/${personId}`)
          .then((res) => {
            toast.success(`Person with id = ${personId} Deleted Successfully`)
          }).catch((err) => {
            toast.error("Failed to delete 😔 person with id " + personId, {
              onClose: window.location.reload()
            })
          })
      }
    } else {
      swal({
        text: "Entry is safe",
        timer: 1500
      }).then((val) => window.location.reload());
    }

  }

  const handleROwsEmails = (rowsSelected) => {  //sendMails to Selected People
    const rows = rowsSelected.data;
    let delay = 0;
    for (let index = 0; index < rows.length; index++) {
      const personId = tableData[rows[index]].person_id;
      const status = tableData[rows[index]].status;
      setTimeout(() => {
        sendMail(personId, status);
      }, delay);
      delay += 2000;
    }
  }

  const sendMail = (id, status) => {     //sendInterview Mail
    if (status === "Accepted") {
      axios
        .post("/api/event/newstage17/sendmail/interview", {
          person_id: id
        }).then((res) => {
          toast.success("Mail Sent Successfully ");
        }).catch((err) => {
          toast.error("Failed to send Mail 😔");
        })
    } else {
      toast.error("Can't send Mail To Rejected People");
    }
  }

  useEffect(() => {
    fetchData()
    let temp = []
    Object.keys(datafieldsname).map((key) => {
      return (temp = [
        ...temp,
        {
          name: `${key}`,
          label: `${datafieldsname[key]}`,
          options:
            typeof datafields[key] === "object"
              ? { filterType: "Multiselect" }
              : { filterType: "textField" },
        },
      ])
    });

    setColumns([
      ...temp,
      {
        name: "Edit Status",
        options: {
          Sort: false,
          filter: false,
          empty: true,
          customBodyRender: (value, tableMeta) => (
            <StatusHandler id={tableMeta.rowData[0]} onSave={handleSaveStatus} />
          )
        }
      },
      {
        name: "Add Comment",
        options: {
          Sort: false,
          filter: false,
          empty: true,
          customBodyRender: (value, tableMeta) => (
            <CommentHandler id={tableMeta.rowData[0]} onSave={handleSaveComment} />
          )
        }
      },
      {
        name: "Send Mail",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <Button
                style={{ backgroundColor: "#700608" }}
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => {
                  const person_id = tableMeta.rowData[0];
                  const status = tableMeta.rowData[9];

                  sendMail(person_id, status);

                }}
              >
                Send Mail
              </Button>
            )
          }
        }
      }
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    selectableRows: "multiple",
    draggableColumns: { enabled: true },
    jumpToPage: true,
    hasIndex: true,
    downloadOptions: {
      filename: `NewStage17Interviews.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowsDelete: {
      handleRowsDelete
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Mails Button */}
          <Button
            style={{ backgroundColor: "#700608", marginRight: "50px" }}
            variant="contained"
            onClick={() => {
              const rowsToEmail = selectedRows.data.map(d => d.dataIndex);
              handleROwsEmails({ data: rowsToEmail });
              setSelectedRows([]);
            }}
            startIcon={<ArrowOutwardOutlinedIcon />}
          >
            Send Mails
          </Button>

          {/*  Delete Button */}
          <IconButton
            aria-label="Delete"
            style={{ Color: "#700608", marginRight: "50px" }}
            onClick={() => {

              const rowsToDelete = selectedRows.data.map(d => d.index);
              // console.log(rowsToDelete);

              handleRowsDelete({ data: rowsToDelete });
              setSelectedRows([]);
            }}
          >
            <DeleteIcon
              style={{ color: "#700608", }}
            />
          </IconButton>
        </div>
      );
    },
  };



  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "flex",
            flexDirection: "row",
            flex: "initial",
          },
        },
      },
    });


  return (
    <>
      <Layout>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`New Stage 17 Interviews`}
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Layout>
    </>
  )

}

