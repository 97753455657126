import React, { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../img/logo.png";
import { UserContext } from "../context/UserContext";
import { FaBars, FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";

/*function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
*/
export default function Navbar(props) {
  const { token } = useContext(UserContext);
  const [show, setShow] = useState(false);
  /* 
 const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    setWindowDimensions(getWindowDimensions());
  }, [window.innerWidth]);
*/
  const handleMenuBtn = () => {
    setShow(!show);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/">
          <motion.img src={logo} alt="logo" animate={window.innerWidth > 745 ? props.anim : 0} />
        </Link>
        <button className="btn" onClick={handleMenuBtn}>
          {show ? <FaTimes /> : <FaBars />}
        </button>
        <ul className={show ? "nav-open" : undefined}>
          <li>
            <NavLink to="/" exact>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/events">Events</NavLink>
          </li>
          <li>
            <NavLink to="/team">Team Hierarchy</NavLink>
          </li>
          <li>
            <NavLink to="/blogs">Blogs</NavLink>
          </li>
          {token && (
            <li>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}
