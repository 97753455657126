import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

export default function AddBlog() {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [disableBtn, setDisbale] = useState(false);
    const { username } = useContext(UserContext);
    const [body, setBody] = useState("");
    const [form, setForm] = useState({
        title: "",
        // body: "",
        username: username,
        cover: ""
    });
    const history = useHistory();
    const [categories, setCategories] = useState(false);

    const getCategories = () => {
        axios
            .get(`/api/categories`)
            .then((res) => {
                console.log(res.data.message);
                setCategories(res.data.message);
            })
            .catch((err) => {
                toast.error("Failed to get categories");
            });
    }

    useEffect(getCategories, []);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        console.log(form);
    };

    const checkBoxStateChange = (e) => {
        if (selectedCategories.indexOf(e.target.attributes.getNamedItem("value").value) === -1) {
            setSelectedCategories([...selectedCategories, e.target.attributes.getNamedItem("value").value]);
            e.target.style.backgroundColor = "#1f1f1f";
        }
        else {
            selectedCategories.splice(selectedCategories.indexOf(e.target.attributes.getNamedItem("value").value), 1)
            setSelectedCategories([...selectedCategories]);
            e.target.style.backgroundColor = "#700608";
        }
    }

    const handleImage = (e) => setForm({ ...form, ["cover"]: e.target.files[0] });

    const handleSubmit = (e) => {
        let formData = new FormData();
        Object.keys(form).map((el) => {
            console.log(el);
            formData.append(el, form[el]);
        })
        formData.append("body", body);
        formData.append("categories", selectedCategories.join(","));
        e.preventDefault();
        setDisbale(true);
        axios
            .post(`/api/blogs`, formData)
            .then((response) => {
                setDisbale(false);
                history.push("/dashboard/blogs");
            })
            .catch((err) => {
                console.log(err);
                setDisbale(false);
                toast.error("Error 😔");
            });
    };

    return (
        <Layout>
            <div className="add_blog">
                <h2 className="title">Add New Blog</h2>
                {
                    categories && (
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label>Title</label>
                                <input type="text" name="title" value={form.title} required onChange={handleChange} />
                            </div>
                            <div className="input-group">
                                <label>Body</label>
                                {/* <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={editorStateChange}
                                    // placeholder="The message goes here..."
                                    plugins= {[rehypeRaw]}
                                /> */}
                                <MDEditor
                                    highlightEnable={false}
                                    value={body}
                                    onChange={setBody}
                                    previewOptions={{
                                        rehypePlugins: [[rehypeSanitize]],
                                    }}
                                />
                            </div>
                            <label htmlFor="">Categories</label>
                            <div className="categories">
                                {
                                    categories.map((el) => {
                                        return (
                                            <div value={el.name} className="check" onClick={checkBoxStateChange}>
                                                {el.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="input-group">
                                <label>Cover</label>
                                <input type="file" onChange={handleImage} />
                            </div>
                            <button className="btn btn-normal" disabled={disableBtn}>
                                <FaPlus style={{ marginRight: 5 }} />
                                Add Blog
                            </button>
                        </form>
                    )
                }


            </div>
        </Layout>
    );
}
