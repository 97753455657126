import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import axios from "axios";
import EventLayout from "../../components/EventLayout";
import { useQrCode } from "../../QrCode";
import Recruitment24Cover from "../../img/events/Recruitment24 Cover.png"; 

export default function Recruitment24() {
    const [formData, setFormData] = useState({ noRepeat: true });
    const [disabled, setDisabled] = useState(false);
    const [qrCode, setQrCode] = useQrCode(null);
    const [showQR, setShowQR] = useState(false);
    const ref = useRef(null);

    const headerprops = {
        maintext: "Recruitment24",
        slogan: "Recruitment24 IS UNDER THE WAY!!",
        backgroundimg: Recruitment24Cover, 
    };

    const Committees = ["PR/FR", "Graphics", "Social Media Marketing", "Technical", "Video"];

    useEffect(() => {
        document.title = `180Daraga | Recruitment24`;
    }, []);

    const isValidEmail = (email) => {
        const emailRegex = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        if (e.target.name === "email") {
            if (e.target.value.indexOf("‏") !== -1) {
                e.target.value = e.target.value.slice(0, e.target.value.lastIndexOf("‏"));
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);

        // Validation for required fields
        if (!formData.name || !formData.phone || !formData.email || 
            !formData.university || !formData.university_level || !formData.college ||
            (formData.university === "Other" && !formData.university_name) || 
            !formData.first_committee || !formData.second_committee || 
            formData.second_committee === formData.first_committee) {
            swal({ title: "Error 😔", text: "Invalid input!", icon: "error" });
            setDisabled(false);
            return;
        }

        // Validate email
        if (!isValidEmail(formData.email)) {
            swal({ title: "Error 😔", text: "Please enter a valid email", icon: "error" });
            setDisabled(false);
            return;
        }

        // Validate phone number
        const phoneNo = /^01[0-9]{9}$/;
        if (!formData.phone.match(phoneNo)) {
            swal({ title: "Error 😔", text: "Please enter a valid phone number\nIn likes of 01XXXXXXXXX", icon: "error" });
            setDisabled(false);
            return;
        }

        axios.post(`/api/events/recruitment24`, formData)
            .then((response) => {
                setShowQR(true);
                setQrCode(response.data.message);
                qrCode.append(ref.current);
            })
            .catch((error) => {
                if (error.response.data.statusCode === 400 && error.response.data.status === "error") {
                    swal({ title: "Oops!", text: "You already have filled this form!", icon: "error" });
                } else {
                    swal({ title: "Error 😔", text: "Something wrong happened!", icon: "error" });
                }
                setDisabled(false);
            });
    };

    return (
        <>
            {!showQR && (
                <EventLayout headerconfig={headerprops}closed= {true}>
                    <div className="cautions">
                        <h3>Cautions</h3>
                        <p style={{ marginTop: "20px" }}>Be sure to check your E-mail after submitting the form!</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {["name", "email", "phone", "college"].map((field) => (
                            <div className="input-group" key={field}>
                                <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                                <input
                                    type={field === "email" ? "email" : "text"}
                                    name={field}
                                    id={field}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        ))}
                        <div className="input-group">
                            <label htmlFor="university">University</label>
                            <select name="university" required onChange={handleChange}>
                                <option value="">Select an option:</option>
                                <option value="Cairo">Cairo</option>
                                <option value="Ain Shams">Ain Shams</option>
                                <option value="Helwan">Helwan</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {formData.university === "Other" && (
                            <div className="input-group">
                                <label htmlFor="university_name">University Name</label>
                                <input type="text" name="university_name" onChange={handleChange} required />
                            </div>
                        )}
                        <div className="input-group">
                            <label htmlFor="university_level">University Level</label>
                            <select name="university_level" required onChange={handleChange}>
                                <option value="">Choose an option:</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="input-group">
                            <label htmlFor="first_committee">First Committee</label>
                            <select name="first_committee" required onChange={handleChange}>
                                <option value="">Choose an option:</option>
                                {Committees.map((el) => (
                                    <option value={el} key={el}>{el}</option>
                                ))}
                            </select>
                        </div>
                        {formData.first_committee && (
                            <div className="input-group">
                                <label htmlFor="second_committee">Second Committee</label>
                                <select name="second_committee" required onChange={handleChange}>
                                    <option value="">Choose an option:</option>
                                    {Committees.filter(el => el !== formData.first_committee).map((el) => (
                                        <option value={el} key={el}>{el}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <button className="btn btn-normal" disabled={disabled}>Submit</button>
                    </form>
                </EventLayout>
            )}
            {showQR && (
                <EventLayout headerconfig={headerprops}>
                    <div className="QRcodeContainer" style={{ textAlign: "center", margin: "30px" }}>
                        <div className="QRcodeContainerText">
                            <p style={{ fontSize: "30px", margin: "20px" }}>Thank You!</p>
                            <p style={{ fontSize: "20px", margin: "20px" }}>
                                Please keep this QR code somewhere safe, as you will need it to attend the event
                            </p>
                        </div>
                        <div ref={ref} />
                    </div>
                </EventLayout>
            )}
        </>
    );
}
