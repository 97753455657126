import React from "react";
import Layout from "./components/Layout";

export default function Dashboard() {
  return (
    <Layout>
      <div className="welcome">
        <h2>Hello World!</h2>
        <p>Welcome to the dashboard ❤</p>
      </div>
    </Layout>
  );
}
