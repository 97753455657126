import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Redirect, Route, useLocation } from "react-router-dom";

export default function ProtectedRoute(props) {
  const { token, role, event_id } = useContext(UserContext);
  const location = useLocation();

  if (token) {
    // if (props.adminOnly && role === "admin")
    //   return (
    //     <Route {...props} path={props.path} exact>
    //       {props.children}
    //     </Route>
    //   );
    // else if (props.adminOnly && role !== "admin") return <Redirect to="/" />;
    if (role !== "admin") {
      if (props.pmOnly) {
        if ((role === "pm" || role === "technical") && event_id.indexOf(props.event_id) !== -1) {
          return (
            <Route {...props} path={props.path} exact>
              {props.children}
            </Route>
          );
        } else {
          return (
            <Redirect to="/404" />
          )
        }
      }
      else if (props.smmOnly) {
        if (role === "smm") {
          return (
            <Route {...props} path={props.path} exact>
              {props.children}
            </Route>
          );
        } else {
          return (
            <Redirect to="/404" />
          )
        }
      }
      else if (props.adminOnly) {
        return (
          <Redirect to="/404" />
        )
      }
      else if (props.charityOnly) {
        if (role === "charity") {
          return (
            <Route {...props} path={props.path} exact>
              {props.children}
            </Route>
          );
        }
        else {
          return (
            <Redirect to="/404" />
          )
        }
      }
    }
    return (
      <Route {...props} path={props.path} exact>
        {props.children}
      </Route>
    );
  }

  return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
}
