import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import { motion } from "framer-motion";

export default function Layout(props) {
  useEffect(() => {
    document.title = "180Daraga | Dashboard";
  }, []);

  return (
    <div className="dashboard">
      <Sidebar />
      <motion.main className="main-content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {props.children}
      </motion.main>
    </div>
  );
}
