import React from "react";
import Slider from "react-slick";
import xiaomi from "../../img/sponsors/xiaomi.png";
import alyom7 from "../../img/sponsors/alyom7.png";
import ibm from "../../img/sponsors/ibm.png";
import alArabiya from "../../img/sponsors/Al-Arabiyalogo.png";
import vodafone from "../../img/sponsors/vodafone.png";
import valeo from "../../img/sponsors/valeo.png";
import penta_value from "../../img/sponsors/penta_value.svg";
import indomie from "../../img/sponsors/indomie.png";
import egypt_food from "../../img/sponsors/egypt_food.png";
import gam3a from "../../img/sponsors/gam3a.jpg";

export default function Sponsers() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          autoplaySpeed: 1000,
        },
      },
    ],
  };

  const sponsers = [
    {
      image: xiaomi,
      name: "Xiaomi",
    },
    {
      image: gam3a,
      name: "First Step Gam3a station",
    },
    {
      image: alyom7,
      name: "alyom7",
    },
    {
      image: alArabiya,
      name: "Al-Arabiya",
    },
    {
      image: ibm,
      name: "IBM",
    },
    {
      image: vodafone,
      name: "Vodafone",
    },
    {
      image: valeo,
      name: "Valeo",
    },
    {
      image: penta_value,
      name: "Penta Value",
    },
    {
      image: indomie,
      name: "Indomie",
    },
    {
      image: egypt_food,
      name: "Egypt Food",
    },
  ];

  return (
    <section className="section sponsers">
      <Slider {...settings}>
        {sponsers.map((el) => (
          <div key={el.name}>
            <img src={el.image} alt={el.name} />
          </div>
        ))}
      </Slider>
    </section>
  );
}
