import React from "react";
import Seif from "../../img/board2025/Seif Tarek.jpg";
import Jana from "../../img/board2025/Jana Alkot.jpg";

export default function Video() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>Video Production Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Seif} alt="" />
            <h3>Seif Tarek</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={Jana} alt="" />
            <h3>Jana Alkot</h3>
            <p>vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
