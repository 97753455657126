import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import swal from "sweetalert";

function DashboardEdit(props) {
  const [newRow, setNewRow] = useState(props.row);

  const handleChange = ({ target: { name, value } }) => {
    setNewRow({ ...newRow, [name]: value });
  };

  const handelOnClick = (e) => {
    e.preventDefault();
    axios
      .patch(props.apipatchendpoint, newRow)
      .then((response) => {
        console.log(response);
        if (response.data.errno === 1062) toast.error("Already Exist");
        else if (!response.data.status) toast.error("Could not update");
        else swal("Data Updated", "success").then((val) => window.location.reload());
      })
      .catch((err) => console.log(err) && toast.error("Error 😔"));
  };
  const back = () => {
    props.hide();
  };
  return (
    <>
      <form>
        {Object.keys(props.datafields).map((key, val) => {
          if (typeof props.datafields[key] === "object") {
            return (
              <div className="input-group" key={val}>
                <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                <select
                  name={`${key}`}
                  id={`${key}`}
                  onChange={handleChange}
                  value={`${newRow[key]}`}
                  required
                >
                  <option value={""}>Select an option:</option>
                  {Array.isArray(props.datafields[key]) &&
                    props.datafields[key].map((e, val) => {
                      return (
                        <option value={`${e}`} key={val}>
                          {e}
                        </option>
                      );
                    })}
                  {
                    //formData[`${props.datafieldsdep[key]}`] === val)

                    !Array.isArray(props.datafields[key]) &&
                      Object.keys(props.datafields[key]).map((ke, vall) => {
                        if (newRow[`${props.datafieldsdep[key]}`] === ke) {
                          return props.datafields[key][ke].map((kk, vv) => {
                            return (
                              <option value={`${kk}`} key={vv}>
                                {kk}
                              </option>
                            );
                          });
                        }
                      })
                  }
                </select>
              </div>
            );
          } else {
            console.log(newRow);
            return (
              <div className="input-group" key={val}>
                <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                <input
                  type={`${props.datafields[key]}`}
                  name={key}
                  id={key}
                  onChange={handleChange}
                  value={`${newRow[key]||""}`}
                  required
                />
              </div>
            );
          }
        })}
        <button className="btn btn-normal" style={{ marginBottom: "5px" }} onClick={handelOnClick}>
          Submit
        </button>
        <button className="btn btn-normal" onClick={back}>
          Back
        </button>
      </form>
    </>
  );
}

export default DashboardEdit;
/*
<div className="input-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            onChange={handleChange}
            value={newRow.name}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="uni">University:</label>
          <input
            type="text"
            name="uni"
            id="uni"
            placeholder="University"
            onChange={handleChange}
            value={newRow.uni}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="level">Level:</label>
          <input
            type="text"
            name="level"
            id="level"
            placeholder="level"
            onChange={handleChange}
            value={newRow.level}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="email">E-mail:</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="example@domain.com"
            onChange={handleChange}
            value={newRow.email}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="01234567899"
            onChange={handleChange}
            value={newRow.phone}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="nid">National ID:</label>
          <input
            type="text"
            name="nid"
            id="nid"
            placeholder="00000000000000"
            onChange={handleChange}
            value={newRow.nid}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="points">Points:</label>
          <input
            type="text"
            name="points"
            id="points"
            placeholder="00"
            onChange={handleChange}
            value={newRow.points}
            required
          />
        </div>
*/
