import React, { useState, useEffect, useRef } from "react";
import SlotCounter from 'react-slot-counter';
import newstage17cover from '../../img/newstage17.png'
import EventLayout from "../../components/EventLayout";
import axios from "axios";
import robot from '../../img/newstage17robot.png'
import redmiLogo from "../../img/redmi.png"
import slotCover from "../../img/backgroundredmi.jpeg"

export default function NewStage17Slot() {

    const [disabled, setDisabled] = useState(false)
    const [winnername, setWinnerName] = useState("a")
    const [winnerphone, setWinnerPhone] = useState("")
    const [winneremail, setWinnerEmail] = useState("")
    const nameRef = useRef(null)
    const phoneRef = useRef(null)
    const emailRef = useRef(null)
    const [data, setData] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showCongrats, setShowCongrats] = useState(false);

    const SplitText = ({ copy, role }) => {
        return (
            <span aria-label={copy} role={role}>
                {copy.split("").map((char, index) => {
                    const style = { animationDelay: (0.5 + index / 10) + "s" };
                    return (
                        <span
                            aria-hidden="true"
                            key={index}
                            style={style}>
                            {char}
                        </span>
                    );
                })}
            </span>
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        document.title = `180Daraga | New Stage 17 Prize`;
        getData();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const boardstyle = {
        backgroundColor: '#700608',
        padding: '20px 22px',
        margin: '0 10px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '14px',
        alignItems: 'center',
    };

    const styles = {
        fontSize: isMobile ? '5vw' : '29px',
        // fontSize: isMobile? '19px': '24px',
        color: 'white',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        textAlign: 'center',
        width: '100%',
        marginBottom: '15px'
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(to right, #1c2432, rgba(47, 76, 98,0)),
            url("${slotCover}")`,
        height: '100vh',
    };

    const robotStyle = {
        width: "15em",
        transform: isMobile ? "rotate(-36deg)" : "none",
    };

    const headerprops = {
        maintext: "NewStage17",
        backgroundimg: newstage17cover,
        slogan: "NewStage17 is under the way"
    };

    const handleSlotClick = () => {
        setDisabled(true)
        let random = Math.floor(Math.random() * data.length);
        let allname = (data[random]?.name).toUpperCase().split(' ');
        console.log(allname);
        let name;
        if (allname.length >= 2)
            name = allname[0] + allname[1]
        else
            name = allname[0]

        let allphone = (data[random]?.phone).split('');
        let phone = `01?????` + allphone[7] + allphone[8] + allphone[9] + allphone[10];
        let allemail = (data[random]?.email).split('@');
        let email = allemail[0] + '@' + 'newstage17.com';
        setWinnerName(name)
        setWinnerPhone(phone)
        setWinnerEmail(email)
        nameRef.current?.startAnimation({
            duration: 3,
            dummyCharacterCount: 26,
            direction: 'top-down',
        })
        setTimeout(() => {
            phoneRef.current?.startAnimation({
                duration: 3,
                dummyCharacterCount: 10,
                direction: "top-down",
            });
        }, 100);


        setTimeout(() => {
            emailRef.current?.startAnimation({
                duration: 1,
                direction: "top-down",
            });
        }, 200);

        setTimeout(() => {
            setShowCongrats(true); // Show congratulations message after animations
        }, 18000);
    };
    const checkEnglishLetter = (c)=>{
        const regex = /^[a-zA-Z]$/;
        return regex.test(c);
    }
    const getData = async () => {
        try {
            const today = new Date().toISOString().split('T')[0]
            console.log(today);

            let daydata = await axios.get(`/api/event/newstage17/attendance?date=${today}`)
            const fetchedData = daydata.data.message;
            console.log(fetchedData);

            setData(fetchedData);
        } catch (error) {
            console.log(error);
        }
    }

    console.log(winnername);
    console.log(winnerphone);
    console.log(winneremail);
    return (
        <>
            <EventLayout headerconfig={headerprops} >
                <div style={containerStyle}>
                    {showCongrats && (
                        <h1 className={'show'}>
                            <SplitText copy="Congratulations!🎉🎁" role="heading" />
                        </h1>
                    )}

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: isMobile ? '100%' : 'none',
                        flexDirection: isMobile ? 'column-reverse' : 'row',
                        justifyContent: 'center'
                    }}>
                        <div className="bounce_container">
                            <img src={robot} alt="robot"
                                style={robotStyle}
                            />
                            <div class="bounce">
                                <img src={redmiLogo} alt="Redmi" className="logo" style={{
                                    transform: isMobile ? "rotate(-36deg)" : "none",
                                }} />
                            </div>
                        </div>

                        <div style={boardstyle}>
                            <div style={styles} className="" dir={!checkEnglishLetter(winnername[0])?"rtl": "ltr"}>
                                <SlotCounter startValue={"180bot"} value={winnername}
                                    ref={nameRef} autoAnimationStart={false} delay={1} startValueOnce={true}
                                    dummyCharacters={"ABCDEFGHIJKLMNOPKRST".split('')} animateUnchanged={true} />
                            </div>

                            <div style={styles}>
                                <SlotCounter startValue={"180bot@newstage17.com"} value={winneremail}
                                    ref={emailRef} autoAnimationStart={false} delay={0.5} startValueOnce={true}
                                    speed={3} dummyCharacters={"ABCDEFGHIJKLMNOPKRST".split('')}
                                />
                            </div>

                            <div style={styles}>
                                <SlotCounter startValue={"01040564660"} value={winnerphone}
                                    ref={phoneRef} autoAnimationStart={false} delay={1.5} startValueOnce={true}
                                    animateUnchanged={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "60%" }}>
                        <button onClick={handleSlotClick} disabled={disabled} className="btn btn-normal" style={{
                            marginbottom: "10px",
                            padding: "13px",
                            fontSize: "24px",
                            letterSpacing: "4px",
                            fontWeight: "600",
                            borderRadius: "15px"
                        }}>SPIN</button>
                    </div>
                </div>
            </EventLayout>
        </>
    );
}