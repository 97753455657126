import React, { useState, useContext, useEffect } from "react";
import logo from "../img/logo.png";
import axios from "axios";
import { toast } from "react-toastify";
import { UserContext } from "../context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "../components/Layout";

export default function Reset() {
  const [email, setEmail] = useState("");
  const { token } = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const location = useLocation();

  if (token) {
    const { from } = location.state || { from: { pathname: "/" } };
    history.replace(from);
  }

  useEffect(() => {
    document.title = "180Daraga | Reset Password";
    if (token) history.push("/"); // if logged in redirect to home page
  }, [token, history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    axios
      .patch("/api/users/resetpassword", {
        email: email,
      })
      .then((response) => {
        toast.success("New Password sent to your email");
        history.push("/login");
      })
      .catch((err) => {
        toast.error("There is an error happend 😔");
        setDisabled(false);
      });
  };

  return (
    <Layout className="login">
      <form onSubmit={handleSubmit}>
        <img src={logo} alt="logo" />
        <div className="input-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button className="btn btn-normal" disabled={disabled}>
          Reset Password
        </button>
      </form>
    </Layout>
  );
}
