import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert2";
import swal1 from "sweetalert";

import SendIcon from "@mui/icons-material/Send";
import { Button, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { AiOutlineQrcode } from "react-icons/ai";
import DashboardEdit from "./components/DashboardEdit";

// import swal from "sweetalert";
/*import { UserContext } from "../context/UserContext";
 */

// import Button from "@mui/material/Button";
// import SendIcon from "@mui/icons-material/Send";
export default function EventFormDashboard(props) {
  const [data, setData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRow, setEditRow] = useState({});
  //  const { token } = useContext(UserContext);
  useEffect(() => {
    setEditRow();
  }, [setShowEditModal]);
  const [columns, setColumns] = useState([
    /*   {
      name: "Send Mail",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              style={{ backgroundColor: "#700608" }}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => {
                console.log(data[tableMeta.rowIndex].id);
                axios
                  .post(`/api/events/startCourse/sendMails`, {
                    id: data[tableMeta.rowIndex].id,
                  })
                  .then((res) => {
                    if (res.data.status) {
                      toast.success(`Mail Sent To ${data[tableMeta.rowIndex].name}`);
                    } else {
                      toast.error(`Sent Recently To ${data[tableMeta.rowIndex].name}`);
                    }
                  })
                  .catch((err) => {
                    toast.error(`can't Send To ${data[tableMeta.rowIndex].name}`);
                    console.log(err);
                  });
              }}
            >
              Send
            </Button>
          );
        },
      },
    },*/
  ]);

  useEffect(() => {
    axios
      .get(`${props.api}`)
      .then((res) => {
        if (props.attendance) {
          let attendance = [];
          res.data.message.forEach((element) => {
            element.eventsattendances.forEach((el) => {
              const person = {
                person_id: element.person_id,
                name: element.name,
                email: element.email,
                phone: element.phone,
                university: element.university,
                university_level: element.university_level,
                data1: element.data1,
                data2: element.data2,
                data3: element.data3,
                comment: element.comment,
                date: el.date,
              };
              attendance.push(person);
            });
          });
          setData(attendance);
        }else  if(res.data.data){
          setData(res.data.data);
        }
        else {
          setData(res.data.message);
        }
        console.log(res.data.message);
        let temp = [];
        Object.keys(props.datafieldsname).map((key, val) => {
          return (temp = [
            ...temp,
            {
              name: `${key}`,
              label: `${props.datafieldsname[key]}`,
              options:
                typeof props.datafields[key] === "object"
                  ? { filterType: "Multiselect" }
                  : { filterType: "textField" },
            },
          ]);
        });

        if (!props.attendance) {
          if (props.QRcode) {
            setColumns([
              ...temp,
              {
                name: "Edit",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,

                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <IconButton
                        style={{ order: -1 }}
                        onClick={() => {
                          console.log(tableMeta.currentTableData[tableMeta.rowIndex]);
                          setShowEditModal(true);
                          setEditRow(tableMeta.currentTableData[tableMeta.rowIndex].index);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    );
                  },
                },
              },
              {
                name: "QR",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <div style={{display: "flex" , flexDirection:"column" , gap: "6px"}}>
                        <Button
                          style={{ backgroundColor: "#700608" , color: "white" }}
                          variant="contained"
                          endIcon={<AiOutlineQrcode />}
                          onClick={() => {
                            const imageUrl =
                              `https://qrickit.com/api/qr.php?d=${tableMeta.rowData[0]}&addtext=180+Daraga&txtcolor=700608&fgdcolor=700608&bgdcolor=FFFFFF&qrsize=300&t=p&e=m`;
                            new swal({
                              html: `<img src='${imageUrl}'/>`,
                              confirmButtonText: "OK",
                            });
                          }}
                        >
                          Generate
                        </Button>
                        <Button
                          style={{ backgroundColor: "#700608", color: "white" }}
                          variant="contained"
                          endIcon={<SendIcon />}
                          onClick={() => {
                            let api = `/api/events/eventsmain/sendmail/qr`;
                            if(props.apiforgetqr)
                              api = `${props.apiforgetqr}`;
                            axios
                              .post(`${api}`, {
                                person_id: tableMeta.rowData[0],
                                docTitle: props.doctitle,
                                event_id: props.eventid
                              })
                              .then((res) => {
                                const imageUrl =
                                  `https://qrickit.com/api/qr.php?d=${tableMeta.rowData[0]}&addtext=180+Daraga&txtcolor=700608&fgdcolor=700608&bgdcolor=FFFFFF&qrsize=300&t=p&e=m`;
                                new swal({
                                  html: `<img src='${imageUrl}'/>`,
                                  confirmButtonText: "OK",
                                });
                              })
                              .catch((err) => {
                                toast.error(`can't Send QR`);
                                console.log(err);
                              });
                          }}
                        >
                          Send
                        </Button>
                      </div>
                    );
                  },
                },
              },
              ...columns,
            ]);
          } else
            setColumns([
              ...temp,
              {
                name: "Edit",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,

                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <IconButton
                        style={{ order: -1 }}
                        onClick={() => {
                          console.log(tableMeta.currentTableData[tableMeta.rowIndex]);
                          setShowEditModal(true);
                          setEditRow(tableMeta.currentTableData[tableMeta.rowIndex].index);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    );
                  },
                },
              },
              ...columns,
            ]);
        } else setColumns([...temp, ...columns]);
      })
      .catch((err) => {
        toast.error("Failed to get data");
      });
  }, []);

  const options = {
    selectableRows: "multiple",
    draggableColumns: { enabled: true },
    jumpToPage: true,
    hasIndex: true,
    downloadOptions: {
      filename: `${props.doctitle}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    /*customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <RowsSelectedDataTable
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        data={data}
      />
    ),*/
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowsDelete: (rowsDeleted) => {
      console.log(rowsDeleted.data[0].dataIndex);
      swal1({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this field!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          for (var i = 0; i < rowsDeleted.data.length; i++) {
            axios
              .delete(
                `${props.apideleteendpoint}/${data[rowsDeleted.data[i].dataIndex].person_id ||
                data[rowsDeleted.data[i].dataIndex].id ||
                data[rowsDeleted.data[i].dataIndex].team_name
                }`
              )
              .then((res) => {
                if (res.data.status) {
                  toast.success("Deleted Successfully");
                } else {
                  console.log(res);
                  toast.error(`Failed with status ${res.data.status}`);
                }
              })
              .catch((err) => {
                toast.error("can't delete");
                console.log(err);
              });
          }
        } else {
          swal1("The entry is safe!").then((val) => window.location.reload());
        }
      });
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "flex",
            flexDirection: "row",
            flex: "initial",
          },
        },
      },
    });

  return (
    <>
      <Layout>
        {!showEditModal && (
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={`${props.doctitle}`}
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        )}
        {!props.attendance && showEditModal && (
          <DashboardEdit
            datafields={props.datafields}
            datafieldsname={props.datafieldsname}
            apipatchendpoint={props.apipatchendpoint}
            row={data[editRow]}
            hide={() => setShowEditModal(false)}
          />
        )}
      </Layout>
    </>
  );
}
