//import image1 from "../../img/events/cover final recruit.jpg";
import theCampCover from "../../img/events/The Camp Cover.jpg";
import slashBackCover from "../../img/events/SlashBack Cover.png";
import SummerCamp22Cover from "../../img/events/summercamp22.png";
import newStage15 from "../../img/events/NewStage15.png";
import RecruitmentCover from "../../img/events/Recruitment22 Cover.png";
import startcourse22Cover from "../../img/events/startcourse22 Cover.png";
import theCamp23Cover from "../../img/events/Tha Camp23 Cover.png";
import PSTcover from "../../img/events/PST1.png";
import summerCamp23Cover from "../../img/events/summerCamp23.png";
import startCourse23Cover from "../../img/events/Start Course 23.png";
import startCourse24Cover from "../../img/events/Start Course 24 Cover.png";
//MAP OF EVENT IDs
//*************************//
// 1- The Camp // 2- Problem Solving // 3- SlashBack // 4- SummerCamp // 5- NewStage // 6- JuniorsJourney // 7- StartCourse // 8- Recruitment // 9- Courses

// Event IDs are separeted into 2 main parts and 1 optional part
// The first represents the entity of the event (i.e what it is, is it newStage or startcourse ...etc)
// The second represents the version (i.e what year it is, is it newStage 14 or newStage15 ...etc)
// Combined we get the main part of the Event ID, used to fetch and post the data to and from the api
// The optional part is the round, many rounds of the same event can be held in the same year (The camp round 1 & round 2 in 2020)

const events = [
  {
    path: "/TheCamp22",
    closed: true,
    doctitle: "The Camp Form",
    headerconfig: {
      maintext: "The Camp22",
      backgroundimg: theCampCover,
      slogan: "The Camp is under the way!",
    },
    cautions: "Please fill in the form below with care",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university_level: ["1", "2", "3", "4"],
      data1: ["Web design", "C++", "Java", "Data Structure", "Flutter"],
      data2: {
        "Web design": ["Friday: 4:00 - 7:00 & Wednesday: 5:00 - 7:00"],
        "C++": ["Friday: 9:00 - 12:00", "Friday: 1:00 - 4:00", "Friday: 2:00 - 5:00"],
        Java: ["Saturday: 4:30 - 6:30"],
        "Data Structure": ["Saturday: 1:00 - 4:00"],
        Flutter: ["Sunday: 1:00 - 4:00 & Wednesday: 1:00 - 4:00"],
      },
    },
    datafieldsname: {
      person_id: "id",
      name: "name",
      email: "email",
      phone: "phone",
      university_level: "Level",
      data1: "Course",
      data2: "Time",
    },
    datafieldsdep: {
      data2: "data1",
    },
    eventid: "1-3",
    apipostendpoint: "/api/events/eventsmain/1-3",
    apigetendpoint: "/api/events/eventsmain/1-3",
    apipatchendpoint: "/api/events/eventsmain/all/1-3",
    apideleteendpoint: "/api/events/eventsmain/1-3",
    QRcode: false,
  },
  {
    path: "/problemsolving22",
    closed: true,
    doctitle: "Problem solving Form",
    headerconfig: {
      maintext: "PS-Training",
      //backgroundimg: theCampCover,
      slogan: "180Daraga Problem Solving Training is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
    },
    datafieldsname: {
      person_id: "id",
      name: "name",
      email: "email",
      phone: "phone",
      university: "university",
      university_level: "Level",
      data1: "Group",
      data2: "Date",
    },
    eventid: "2-2",
    apipostendpoint: "/api/events/eventsmain/2-2",
    apigetendpoint: "/api/events/eventsmain/2-2",
    apipatchendpoint: "/api/events/eventsmain/all/2-2",
    apideleteendpoint: "/api/events/eventsmain/2-2",
    sendMail: {
      mailTitle: "ProblemSolving'22",
      mailHeadParagraph: "Hello great problem solvers ❤️ <br><br>",
      mailBody: `
      We want to inform you that you have registered successfully , you will find a QR code bellow kindly scan it and save the appeared ID as you will use it in an upcoming form .<br><br>
      save your ID in safe place and be ready for our journey together 😌<br><br>
      
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/2-2",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/2-2",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "Group",
      data2: "Date",
      date: "Attended at",
    },
  },
  {
    updateForm: true,
    path: "/problemsolving22Date",
    closed: true,
    doctitle: "Problem solving Date Form",
    headerconfig: {
      maintext: "PS-Training",
      //backgroundimg: theCampCover,
      slogan: "180Daraga Problem Solving Training Date Form",
    },
    cautions:
      "Check your E-mail for the QR code with your ID\nUse the same Phone Number that you provided in your registerion",
    datafields: {
      person_id: "text",
      phone: "text",
      data1: [
        "Saturday - Wednesday",
        "Monday - Friday",
        "Saturday - Tuesday",
        "Monday - Thursday",
        "Sunday - Wednesday",
      ],
      data2: {
        "Saturday - Wednesday": ["4PM - 7PM"],
        "Monday - Friday": ["4PM - 7PM"],
        "Saturday - Tuesday": ["4PM - 7PM"],
        "Monday - Thursday": ["4PM - 7PM"],
        "Sunday - Wednesday": ["4PM - 7PM"],
      },
    },
    datafieldsname: {
      person_id: "ID",
      phone: "phone",
      data1: "Group",
      data2: "Time",
    },
    datafieldsdep: {
      data2: "data1",
    },
    eventid: "2-2",
    apipostendpoint: "/api/events/eventsmain/all/2-2",
    apigetendpoint: "/api/events/eventsmain/2-2",
    apideleteendpoint: "/api/events/eventsmain/2-2",
    constraint: "phone",
  },
  {
    path: "/SlashBack22",
    closed: true,
    doctitle: "SlashBack Form",
    headerconfig: {
      maintext: "SlashBack",
      backgroundimg: slashBackCover,
      slogan: "Invade The Desert",
    },
    cautions:
      "Please note that only Helwan univerity students can apply and attend the event\t Be sure to check your email",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university_level: ["1", "2", "3", "4"],
    },
    datafieldsname: {
      person_id: "id",
      name: "name",
      email: "email",
      phone: "phone",
      university_level: "Level",
    },
    eventid: "3-1",
    apipostendpoint: "/api/events/eventsmain/3-1",
    apigetendpoint: "/api/events/eventsmain/3-1",
    apipatchendpoint: "/api/events/eventsmain/all/3-1",
    apideleteendpoint: "/api/events/eventsmain/3-1",
    QRcode: true,
    sendMail: {
      mailTitle: "SlashBack'22",
      mailHeadImage:
        "https://scontent.fcai19-7.fna.fbcdn.net/v/t39.30808-6/274497150_4833316916754769_2458422655179217088_n.jpg?stp=dst-jpg_p960x960&_nc_cat=108&ccb=1-5&_nc_sid=36a2c1&_nc_ohc=LuHSbCkiBe4AX8HReAq&_nc_ht=scontent.fcai19-7.fna&oh=00_AT9sc_E6X1a8VEP2j5hbW1uDt3NPnrcSIk-qgBTkeCqlzQ&oe=6218F241",
      mailBody: `We will help you to invade the desert, it is a land full of magic and mystery! <br><br>
      It will not be an easy journey, so be well prepared.<br><br>
      Below you will find a QR code, Be sure to save it somewhere safe as it is your entry ticket<br><br>
      `,
    },
  },
  {
    path: "/summercamp22",
    closed: true,
    doctitle: "SummerCamp Form",
    headerconfig: {
      maintext: "SummerCamp'22",
      backgroundimg: SummerCamp22Cover,
      slogan: "The Summercamp'22 event is under the way!!",
    },
    cautions: "Fill in the following form with care, please 😇",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["Algorithm", "Back-end", "Flutter", "Front-end", "OOP"],
    },
    datafieldsname: {
      person_id: "id",
      name: "name",
      email: "email",
      phone: "phone",
      university: "university",
      university_level: "Level",
      data1: "Courses",
    },
    eventid: "4-1",
    apipostendpoint: "/api/events/eventsmain/4-1",
    apigetendpoint: "/api/events/eventsmain/4-1",
    apipatchendpoint: "/api/events/eventsmain/all/4-1",
    apideleteendpoint: "/api/events/eventsmain/4-1",
    QRcode: true,
    /*sendMail: {
      mailTitle: "Summer Camp'22",
      mailHeadImage:
        "https://scontent.fcai19-7.fna.fbcdn.net/v/t39.30808-6/274497150_4833316916754769_2458422655179217088_n.jpg?stp=dst-jpg_p960x960&_nc_cat=108&ccb=1-5&_nc_sid=36a2c1&_nc_ohc=LuHSbCkiBe4AX8HReAq&_nc_ht=scontent.fcai19-7.fna&oh=00_AT9sc_E6X1a8VEP2j5hbW1uDt3NPnrcSIk-qgBTkeCqlzQ&oe=6218F241",
      mailBody: `Welcome to the summer camp <br><br>
      `,
    },*/
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/4-1",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/4-1",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "Course",
      date: "Attended at",
    },
  },
  // Here Lies The charity forms
  {
    protected: true,
    path: "/charity/institutes",
    doctitle: "institutes Form",
    charity: true,
    headerconfig: {
      maintext: "Institutes",
      //backgroundimg: theCampCover,
      slogan: "Institutes Form",
    },
    cautions: "Please fill in the form below with care",
    datafields: {
      name: "text",
      connect: "text",
      type: ["شركة", "جمعية", "دار أيتام", "دار مسنين", "تاجر"],
      status: ["Approved", "Waiting List", "BlackList"],
      comment: "textarea",
    },
    datafieldsname: {
      id: "ID",
      name: "Name",
      connect: "Connect",
      type: "Institute Type",
      status: "Status",
      comment: "Comment",
    },
    apipostendpoint: "/api/events/charitymain/institutes",
    apigetendpoint: "/api/events/charitymain/institutes",
    apipatchendpoint: "/api/events/charitymain/institutes",
    apideleteendpoint: "/api/events/charitymain/institutes",
  },
  {
    protected: true,
    path: "/charity/buses",
    doctitle: "Buses Form",
    charity: true,
    headerconfig: {
      maintext: "Buses",
      //backgroundimg: theCampCover,
      slogan: "Buses Form",
    },
    cautions: "Please fill in the form below with care",
    datafields: {
      name: "text",
      connect: "text",
      status: ["Single", "Company"],
      type: ["Coaster", "Mini-Bus", "Both"],
      comment: "textarea",
    },
    datafieldsname: {
      id: "ID",
      name: "Name",
      connect: "Connect",
      status: "Status",
      type: "Bus Type",
      comment: "Comment",
    },
    apipostendpoint: "/api/events/charitymain/buses",
    apigetendpoint: "/api/events/charitymain/buses",
    apipatchendpoint: "/api/events/charitymain/buses",
    apideleteendpoint: "/api/events/charitymain/buses",
  },
  {
    path: "/problemsolving22LVL1",
    closed: true,
    doctitle: "Problem solving LVL1 Form",
    headerconfig: {
      maintext: "PS-Training LVL-1",
      //backgroundimg: theCampCover,
      slogan: "180Daraga Problem Solving Training level 1 is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["Yes", "No"],
      data2: "text",
    },
    datafieldsname: {
      person_id: "id",
      name: "name",
      email: "email",
      phone: "phone",
      university: "university",
      university_level: "Level",
      data1: "Attended Level 0?",
      data2: "Codeforces Handle",
    },
    eventid: "2-2-2",
    apipostendpoint: "/api/events/eventsmain/2-2-2",
    apigetendpoint: "/api/events/eventsmain/2-2-2",
    apipatchendpoint: "/api/events/eventsmain/all/2-2-2",
    apideleteendpoint: "/api/events/eventsmain/2-2-2",
    sendMail: {
      mailTitle: "ProblemSolving'22",
      mailHeadParagraph: "Hello great problem solvers ❤️ <br><br>",
      mailBody: `
      We want to inform you that you have registered successfully , you will find a QR code bellow kindly scan it and save the appeared ID as you will use it in an upcoming form .<br><br>
      save your ID in safe place and be ready for our journey together 😌<br><br>
      
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/2-2-2",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/2-2-2",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "Attended LVL0",
      data2: "Codeforces handle",
      date: "Attended at",
    },
  },
  {
    path: "/newstage15",
    closed: true,
    doctitle: "New Stage 15 Form",
    headerconfig: {
      maintext: "New Stage 15",
      backgroundimg: newStage15,
      slogan: "Explore your parallel worlds!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      data1: "text",
      university: ["Cairo", "Ain Shams", "Helwan", "Other"],
      data2: "text",
      university_level: ["1", "2", "3", "4"],
    },
    datafieldsname: {
      person_id: "id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      data1: "National ID",
      university: "University",
      data2: "College",
      university_level: "Level",
      data3: "interview time",
    },
    eventid: "5-15",
    apipostendpoint: "/api/events/eventsmain/5-15",
    apigetendpoint: "/api/events/eventsmain/5-15",
    apipatchendpoint: "/api/events/eventsmain/all/5-15",
    apideleteendpoint: "/api/events/eventsmain/5-15",
    QRcode: true,
    feedback: true,
    feedbackconfig: {
      path: "/newstage15/feedback",
      doctitle: "New Stage15 Feedback",
      datafields: {
        name: "text",
        data1: "text",
        data2: "text",
        data3: "textarea",
        data4: "textarea",
      },
      feedbackcautions:
        "After discovering all the universises along the past 5 days,we want to thank you for your attendance and we're waiting for your feedback",
      datafieldsname: {
        name: "Name",
        data1: "What's the session you like the most",
        data2: "A Session you wanted but it didn't exist",
        data3: "Advantages",
        data4: "Disadvantages",
      },
      feedbackapipostendpoint: "/api/events/eventsmain/feedback/5-15",
      feedbackapigetendpoint: "/api/events/eventsmain/feedback/5-15",
    },
    sendMail: {
      mailTitle: "New Stage 15",
      mailHeadImage: "",
      mailBody: `It's time to select your right place... Redirect your thinking <br><br>
      Explore your parallel worlds!<br><br>
      Below you will find a QR code, Be sure to save it somewhere safe as it is your entry ticket<br><br>
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/5-15",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/5-15",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "National ID",
      university_level: "Level",
      date: "Attended at",
    },
  },
  {
    path: "/juniorsjourney",
    closed: true,
    doctitle: "Juniors Journey Form ",
    headerconfig: {
      maintext: "Juniors Form",
      slogan: "Juniors Journey Form is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Secondry ", "Preparatory", "Primary", "Other"],
      university_level: ["1", "2", "3", "4", "5", "6"],
      data1: "text",
      data2: "text",
    },
    datafieldsname: {
      person_id: "Id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: " ",
      university_level: "Level",
      data1: "School Name",
      data2: "City (like:Dokki)",
    },
    eventid: "6-1",
    apipostendpoint: "/api/events/eventsmain/6-1",
    apigetendpoint: "/api/events/eventsmain/6-1",
    apipatchendpoint: "/api/events/eventsmain/all/6-1",
    apideleteendpoint: "/api/events/eventsmain/6-1",
    sendMail: {
      mailTitle: "Juniors Journey",
      mailHeadParagraph: "Hello great Developer ❤️ <br><br>",
      mailBody: `
      We want to inform you that you have registered successfully , you will find a QR code bellow kindly scan it and save the appeared ID as you will use it in an upcoming form .<br><br>
      save your ID in safe place and be ready for our journey together 😌<br><br>
      
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/6-1",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/6-1",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "School Name",
      date: "Attended at",
    },
  },
  {
    path: "/startcourse22",
    closed: true,
    doctitle: "Start Course Form ",
    headerconfig: {
      maintext: "StartCourse",
      backgroundimg: startcourse22Cover,
      slogan: "Start Course is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["C++", "Python", "C"],
    },
    datafieldsname: {
      person_id: "Id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: "University",
      university_level: "University Level",
      data1: "Course",
    },
    eventid: "7-10",
    apipostendpoint: "/api/events/eventsmain/7-10",
    apigetendpoint: "/api/events/eventsmain/7-10",
    apipatchendpoint: "/api/events/eventsmain/all/7-10",
    apideleteendpoint: "/api/events/eventsmain/7-10",
    sendMail: {
      mailTitle: "IT'S TIME TO START !",
      mailHeadParagraph: "Welcome in Start Course'22 , <br><br>",
      mailBody: `
      You have Successfully Choose Your New Chess Piece,
      Below you Will Find A Qr Code Make Sure To Save It In Safe Place As You Will Use It In Attending The Course...your Sincere 180Daraga Team ❤️ .<br><br>
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/7-10",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/7-10",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "University",
      date: "Attended at",
    },
  },
  {
    path: "/recruitment22",
    // closed: true,
    doctitle: "Recruitment Form",
    headerconfig: {
      maintext: "Recruitment22",
      backgroundimg: RecruitmentCover,
      slogan: "Let us develop our city!!",
    },
    cautions: "Please fill in the form below with care",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],

      data1: {
        Other: "string",
      },
      data2: ["PR/FR", "Graphics", "Social Media Marketing", "Video", "Technical"],
      data3: {
        "PR/FR": ["Graphics", "Social Media Marketing", "Video", "Technical"],
        Graphics: ["PR/FR", "Social Media Marketing", "Video", "Technical"],
        "Social Media Marketing": ["PR/FR", "Graphics", "Video", "Technical"],
        Video: ["PR/FR", "Graphics", "Social Media Marketing", "Technical"],
        Technical: ["PR/FR", "Graphics", "Social Media Marketing", "Video"],
      },
    },
    datafieldsname: {
      person_id: "id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: "University",
      university_level: "Level",
      data1: "Other University Name",
      data2: "First Committee",
      data3: "Second Committee",
    },
    noRepeat: true,
    datafieldsdep: {
      data1: "university",
      data3: "data2",
    },
    eventid: "8-15",
    apipostendpoint: "/api/events/eventsmain/8-15",
    apigetendpoint: "/api/events/eventsmain/8-15",
    apipatchendpoint: "/api/events/eventsmain/all/8-15",
    apideleteendpoint: "/api/events/eventsmain/8-15",
    QRcode: true,
    sendMail: {
      mailTitle: "180Daraga 2022 Recruitment",
      mailHeadParagraph: "Welcome in Our Recruitment22 ❤️ <br><br>",
      mailBody: `
      It's time to select your right place in the process of Developing Our City To redirect your thinking ❤️ <br><br>
      You are invited to be interviewed on saturday 12/11 or on saturday 19/12 at First Step at Giza <a href="https://maps.app.goo.gl/qhpZFATGt3RKvHG88">Location</a>
      Below you will find a QR code, Be sure to save it somewhere safe as it is your entry ticket. .<br><br>
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/8-15",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/8-15",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "Other University",
      data2: "First Committee",
      data3: "Second Committee",
      date: "Attended at",
    },
  },
  {
    path: "/thecamp23",
    doctitle: "The Camp Form",
    headerconfig: {
      maintext: "TheCamp23",
      slogan: "Adventure Awaits!",
      backgroundimg: theCamp23Cover,
    },
    cautions: "Please fill in the form below with care",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university_level: ["1", "2", "3", "4"],
      university: ["Cairo", "Ain Shams", "Helwan", "Other"],
      data2: ["Computer science", "Engineering", "science", "other"],
      data1: [
        "Data Structure (280 L.E)",
        "C++ (250 L.E)",
        "Machine Learning (350 L.E)",
        "Back-End (350 L.E)",
        "Testing (320 L.E)",
      ],
    },
    datafieldsname: {
      person_id: "id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: "University",
      university_level: "Level",
      data1: "Course",
      data2: "Faculty",
    },
    eventid: "1-4",
    apipostendpoint: "/api/events/eventsmain/1-4",
    apigetendpoint: "/api/events/eventsmain/1-4",
    apipatchendpoint: "/api/events/eventsmain/all/1-4",
    apideleteendpoint: "/api/events/eventsmain/1-4",
    QRcode: true,
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/1-4",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/1-4",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "Course",
      date: "Attended at",
    },
    sendMail: {
      mailTitle: "The Camp 23",
      mailBody: "Please keep this QRcode somewhere safe, as you will need it to attend the event",
    },
  },
  {
    path: "/problemsolving23",
    doctitle: "Problem solving Form",
    // closed:true,
    headerconfig: {
      maintext: "PS-Training",
      backgroundimg: PSTcover,
      slogan: "180Daraga Problem Solving Training is under the way!",
    },
    QRcode: true,
    cautions: `Be sure to check your E-mail after submitting the form!`,
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data2: ["0", "1"],
      data1: "text",
    },
    datafieldsname: {
      person_id: "id",
      name: "Name",
      email: "Email",
      phone: "Phone (WhatsApp)",
      university: "University",
      university_level: "University Level",
      data2: "Level",
      data1: "Codeforces Handle",
    },
    eventid: "2-3",
    apipostendpoint: "/api/events/eventsmain/2-3",
    apigetendpoint: "/api/events/eventsmain/2-3",
    apipatchendpoint: "/api/events/eventsmain/all/2-3",
    apideleteendpoint: "/api/events/eventsmain/2-3",
    sendMail: {
      mailTitle: "ProblemSolving'23",
      mailHeadParagraph: "Welcome to Solvea and Techy-tan squad!<br><br>",
      mailBody: `
      We are pleased to inform you that you have successfully enrolled in the Problem Solving Training. We hope that you are excited to embark on this learning journey with us.
      <br><br>
      As a reminder, please keep the QR code attached to this email. It will be required for attendance. Please ensure that you have it readily available before the start of the first session.
      <br><br>
      Thank you for your enrollment, and we look forward to seeing you in class.
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/2-3",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/2-3",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data2: "Level",
      data1: "Codeforces handle",
      date: "Attended at",
    },
  },
  {
    path: "/summercamp23",
    // closed: true,
    doctitle: "SummerCamp Form",
    headerconfig: {
      maintext: "SummerCamp'23",
      backgroundimg: summerCamp23Cover,
      slogan: "The Summercamp'23 event is under the way!!",
    },
    cautions: "Fill in the following form with care, please 😇",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["OOP", "Algorithm", "Frontend Angular", "Backend Laravel", "Flutter"],
    },
    datafieldsname: {
      person_id: "id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: "University",
      university_level: "Level",
      data1: "Course",
    },
    eventid: "4-2",
    apipostendpoint: "/api/events/eventsmain/4-2",
    apigetendpoint: "/api/events/eventsmain/4-2",
    apipatchendpoint: "/api/events/eventsmain/all/4-2",
    apideleteendpoint: "/api/events/eventsmain/4-2",
    QRcode: true,
    /*sendMail: {
      mailTitle: "Summer Camp'23",
      mailHeadImage:
        "https://",
      mailBody: `Welcome to the SummerCamp <br><br>
      `,
    },*/
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/4-2",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/4-2",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "Course",
      date: "Attended at",
    },
  },
  {
    path: "/startcourse23",
    closed: true,
    doctitle: "Start Course Form ",
    headerconfig: {
      maintext: "StartCourse",
      backgroundimg: startCourse23Cover,
      slogan: "Start Course is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["C++", "Python", "C"],
    },
    datafieldsname: {
      person_id: "Id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: "University",
      university_level: "University Level",
      data1: "Course",
    },
    eventid: "7-11",
    apipostendpoint: "/api/events/eventsmain/7-11",
    apigetendpoint: "/api/events/eventsmain/7-11",
    apipatchendpoint: "/api/events/eventsmain/all/7-11",
    apideleteendpoint: "/api/events/eventsmain/7-11",
    sendMail: {
      mailTitle: "IT'S TIME TO START !",
      mailHeadParagraph: "Welcome in Start Course'23 , <br><br>",
      mailBody: `
      We are ready to guide you through your game. ✨ <br><br>
      Below you Will Find A Qr Code Make Sure To Save It In Safe Place As You Will Use It In Attending The Course<br><br>
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/7-11",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/7-11",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "Course",
      date: "Attended at",
    },
    QRcode: true,
  },
  {
    path: "/CoursesWave1",
    closed: true,
    doctitle: "Courses Wave 1",
    headerconfig: {
      maintext: "Courses Wave 1",
      slogan: "Courses Wave 1 is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["C++ & OOP", "Data Structure"],
    },
    datafieldsname: {
      person_id: "Id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: "University",
      university_level: "University Level",
      data1: "Course",
    },
    eventid: "9-1",
    apipostendpoint: "/api/events/eventsmain/9-1",
    apigetendpoint: "/api/events/eventsmain/9-1",
    apipatchendpoint: "/api/events/eventsmain/all/9-1",
    apideleteendpoint: "/api/events/eventsmain/9-1",
    sendMail: {
      mailTitle: "IT'S TIME TO START !",
      mailBody: `
      Are you ready to solve puzzles and learn how to master coding? 🤩<br><br>

      Below you will find a QR Code, make sure to save it somewhere safe as it is your entry ticket to attend the course.<br><br>
      
      We can't wait to witness the incredible achievements you'll accomplish throughout the course. ❤<br><br>
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/9-1",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/9-1",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "University",
      date: "Attended at",
    },
    QRcode: true,
  },
  {
    path: "/CoursesWave2",
    closed: false,
    doctitle: "Courses Wave 2",
    headerconfig: {
      maintext: "Courses Wave 2",
      slogan: "Courses Wave 2 is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["Software Engineering", "Algorithms", "Flutter"],
    },
    datafieldsname: {
      person_id: "Id",
      name: "Name",
      email: "Email",
      phone: "Phone",
      university: "University",
      university_level: "University Level",
      data1: "Course",
    },
    eventid: "9-2",
    apipostendpoint: "/api/events/eventsmain/9-2",
    apigetendpoint: "/api/events/eventsmain/9-2",
    apipatchendpoint: "/api/events/eventsmain/all/9-2",
    apideleteendpoint: "/api/events/eventsmain/9-2",
    sendMail: {
      mailTitle: "IT'S TIME TO START !",
      mailBody: `
      Are you ready to solve puzzles and learn how to master coding? 🤩<br><br>

      Below you will find a QR Code, make sure to save it somewhere safe as it is your entry ticket to attend the course.<br><br>
      
      We can't wait to witness the incredible achievements you'll accomplish throughout the course. ❤<br><br>
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/9-2",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/9-2",
    attendancedatafieldsname: {
      person_id: "id",
      name: "name",
      phone: "phone",
      data1: "University",
      date: "Attended at",
    },
    QRcode: true,
  },
  {
    path: "/startcourse24",
    closed: true,
    doctitle: "Start Course Form ",
    headerconfig: {
      maintext: "StartCourse",
      backgroundimg: startCourse24Cover,
      slogan: "Start Course is under the way!",
    },
    cautions: "Be sure to check your E-mail after submitting the form!",
    datafields: {
      name: "text",
      email: "email",
      phone: "text",
      comment: "text",
      university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
      university_level: ["1", "2", "3", "4"],
      data1: ["C++", "Python", "C", "Problem Solving Level 0"],
    },
    datafieldsname: {
      person_id: "ID",
      name: "Name",
      email: "Email",
      phone: "Phone",
      comment: "District",
      university: "University",
      university_level: "University Level",
      data1: "Course",
    },
    eventid: "7-12",
    apipostendpoint: "/api/events/eventsmain/7-12",
    apigetendpoint: "/api/events/eventsmain/7-12",
    apipatchendpoint: "/api/events/eventsmain/all/7-12",
    apideleteendpoint: "/api/events/eventsmain/7-12",
    sendMail: {
      mailTitle: "IT'S TIME TO START !",
      mailHeadParagraph: "Welcome in Start Course'24 , <br><br>",
      mailBody: `
      We are ready to guide you through your Journey.✨<br><br>
      Below you Will Find A Qr Code Make Sure To Save It In Safe Place As You Will Use It In Attending The Course<br><br>
      `,
    },
    attendance: true,
    apiattendancepostendpoint: "/api/events/eventsmain/attendance/7-12",
    apiattendancegetendpoint: "/api/events/eventsmain/attendance/7-12",
    attendancedatafieldsname: {
      person_id: "ID",
      name: "Name",
      phone: "Phone",
      data1: "Course",
      data2: "Group",
      data3: "Score",
      date: "Attended at",
    },
    QRcode: true,
  }
];
export default events;
