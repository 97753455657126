import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import StatusHandler from "./components/StatusHandler";

const datafieldsname = {
  person_id: "ID",
  name: "Name",
  email: "Email",
  phone: "Phone",
  university: "University",
  university_name: "University Name",
  university_level: "University Level",
  first_committee: "First Committee",
  second_committee: "Second Committee",
  date: "Date",
  status: "Status",
};

const datafields = {
  person_id: "text",
  name: "text",
  email: "email",
  phone: "text",
  university: ["Cairo", "Ain Shams", "Helwan", "Other"],
  university_name: "text",
  university_level: ["1", "2", "3", "4"],
  first_committee: ["PR/FR", "Graphics", "Social Media Marketing", "Technical", "Video"],
  second_committee: ["PR/FR", "Graphics", "Social Media Marketing", "Technical", "Video"],
  date: "text",
  status: ["Accepted", "Rejected"],
};

function Recruitment23FirstInterview() {
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);

  const formatDate = (date) => {
    const newDateFormat = new Date(date);

    const year = newDateFormat.getFullYear();
    const month = String(newDateFormat.getMonth() + 1).padStart(2, "0");
    const day = String(newDateFormat.getDate()).padStart(2, "0");
    const hours = String(newDateFormat.getHours()).padStart(2, "0");
    const minutes = String(newDateFormat.getMinutes()).padStart(2, "0");

    const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateString;
  };

  // handle save button for status section
  const handleSaveStatus = (id, newStatus) => {
    console.log(id,newStatus);
    axios
      .patch("/api/events/recruitment23/personstatus", {
        person_id: id,
        status: newStatus,
      })
      .then((res) => {
        console.log(res.data.message);
        console.log(id);
        swal({
          icon: "success",
          title: "Saved Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        axios
          .get(`/api/events/recruitment23/attendance`)
          .then((res) => {
            console.log(res.data.message);
            setTableData(res.data.message);
          })
          .catch((error) => {
            toast.error("Failed to get data: " + error);
          });
      })
      .catch((error) => {
        toast.error("Failed to update data: " + error);
      });
  };

  // get table data
  useEffect(() => {
    axios
      .get(`/api/events/recruitment23/attendance`)
      .then((res) => {
        let attendance = [];
         res.data.message.map((element) => {
           element.recruitment23_attendances?.map((el) => {
            const person = {
              person_id: el.person_id,
              name: element.name,
              phone: element.phone,
              university: element.university,
              university_name: element.university_name,
              university_level: element.university_level,
              first_committee: element.first_committee,
              second_committee: element.second_committee,
              date: el.date,
              status: element.status,
            };
            attendance.push(person);
          });
        });

        console.log(attendance);
        const formattedTableData = attendance.map((element) => {
          const oldDate = element["date"];
          element["date"] = formatDate(oldDate);
          return element;
        });

        setTableData(formattedTableData);

        let temp = [];
        Object.keys(datafieldsname).map((key, val) => {
          return (temp = [
            ...temp,
            {
              name: `${key}`,
              label: `${datafieldsname[key]}`,
              options:
                typeof datafields[key] === "object"
                  ? { filterType: "Multiselect" }
                  : { filterType: "textField" },
            },
          ]);
        });

        // set columns of table
        setColumns([
          ...temp,
          {
            name: "Edit Status",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta) => (
                <StatusHandler id={tableMeta.rowData[0]} onSave={handleSaveStatus} />
              ),
            },
          },
        ]);
      })
      .catch((error) => {
        toast.error("Failed to get data " + error);
      });
  }, []);

  const options = {
    selectableRows: "multiple",
    draggableColumns: { enabled: true },
    jumpToPage: true,
    hasIndex: true,
    downloadOptions: {
      filename: `Recruitment23Interviews.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "flex",
            flexDirection: "row",
            flex: "initial",
          },
        },
      },
    });

  return (
    <>
      <Layout>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`Recruitment 23 Interviews`}
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Layout>
    </>
  );
}

export default Recruitment23FirstInterview;
