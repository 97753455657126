import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router";
import Layout from "./components/Layout";
import { FaEdit } from "react-icons/fa";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
// import {stateFromMarkdown} from 'draft-js-import-markdown';
// import draftToHTML from 'draftjs-to-markdown';
// import { convertToRaw }from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState } from 'draft-js';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function EditBlog() {
    const [blog, setBlog] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const [disableBtn, setDisbale] = useState(false);
    const [categories, setCategories] = useState(false);
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [body,setBody] = useState("");

    const getCategories = () => {
        axios
            .get(`/api/categories`)
            .then((res) => {
                console.log(res.data.message);
                setCategories(res.data.message);
            })
            .catch((err) => {
                toast.error("Failed to get categories");
            });
    }

    const handleChange = (e) => {
        setBlog({ ...blog, [e.target.name]: e.target.value });
        console.log(blog);
    }


    const handleSubmit = (e) => {
        e.preventDefault(); 
        setDisbale(true);
        axios   
            .patch(`/api/blogs/${blog.id}`,{
                title:blog.title,
                body: body,
                categories:selectedCategories.join(",")
            })   
            .then((response) => {
                setDisbale(false);
                history.push("/dashboard/blogs");
            })
            .catch((err) => {
                console.log(err);
                setDisbale(false);
                toast.error("Error 😔");
            });
    };

    const checkBoxStateChange = (e) => {
        if (selectedCategories.indexOf(e.target.attributes.getNamedItem("value").value) === -1) {
            setSelectedCategories([...selectedCategories, e.target.attributes.getNamedItem("value").value]);
            e.target.style.backgroundColor = "#1f1f1f";
        }
        else {
            selectedCategories.splice(selectedCategories.indexOf(e.target.attributes.getNamedItem("value").value), 1)
            setSelectedCategories([...selectedCategories]);
            e.target.style.backgroundColor = "#700608";
        }
    }
    
    useEffect(() => {
        document.title = "180Daraga | Blogs";
        axios
            .get(`/api/blogs/${id}`)
            .then((response) => {
                if (response.data.message.length === 0) history.push("/404");
                    setBlog(response.data.message[0]);
                // setEditorState(EditorState.createWithContent(stateFromMarkdown(response.data.message[0].body)));
                setSelectedCategories([...response.data.message[0].categories.split(",")]);
                setBody(response.data.message[0].body);
                getCategories();
            })
            .catch((err) => console.log(err));
    }, []); 

    return (
        <Layout>    
            <div className="add_blog">
                <h2 className="title">Edit Blog</h2>
                {
                    categories && (
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label>Title</label>
                                <input type="text" name="title" value={blog.title} required onChange={handleChange} />
                            </div>
                            <div className="input-group">
                                <label>Body</label>
                                <MDEditor
                                    highlightEnable={false}
                                    value={body}
                                    onChange={setBody}
                                    previewOptions={{
                                        rehypePlugins: [[rehypeSanitize]],
                                    }}
                                />
                                {/* <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={editorStateChange}
                                    // placeholder="The message goes here..."
                                    plugins= {[rehypeRaw]}
                                /> */}
                            </div>
                            <label htmlFor="">Categories</label>
                            <div className="categories">
                                {
                                    categories.map((el) => {
                                        return (
                                            <div value={el.name} className="check" onClick={checkBoxStateChange} style={{backgroundColor:selectedCategories.indexOf(el.name)== -1 ? "#700608" : "#1f1f1f"}}>
                                                {el.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <button className="btn btn-normal" disabled={disableBtn} style={{marginTop:10}}>
                                <FaEdit style={{ marginRight: 5 }} />
                                Edit Blog
                            </button>
                        </form>
                    )
                }
            </div>
        </Layout>
    )
}
