import axios from "axios";
import Layout from "./components/Layout";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { FaPlus, FaTrash, FaEdit } from "react-icons/fa";
import DashboardEdit from "./components/DashboardEdit"

export default function Categories() {
    const [categories, setCategories] = useState([]);
    const [edit, setEdit] = useState(false);
    const [category, setCategory] = useState([]);

    const getCategories = () => {
        axios
            .get(`/api/categories`)
            .then((response) => {
                setCategories(response.data.message);
            })
            .catch((err) => toast.error(err));
    };

    useEffect(getCategories, []);

    const deleteCategory = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the category!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Poof! Category has been deleted!", {
                    icon: "success",
                });
                axios
                    .delete(`/api/Categories/${id}`)
                    .then((response) => {
                        getCategories();
                    })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((err) => toast.error("Error 😔"));
            } else {
                swal("Category is safe!");
            }
        });
    };

    return (
        <Layout>
            {
                !edit && (
                    <div className="table">
                        <h2 className="title">Categories</h2>
                        <Link to="/dashboard/Categories/add" className="btn btn-success">
                            <FaPlus style={{ marginRight: 5 }} />
                            Add New
                        </Link>
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((el) => (
                                    <tr key={el.id}>
                                        <td>{el.id}</td>
                                        <td>{el.name}</td>
                                        <td>
                                            <button
                                                className="btn btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteCategory(el.id);
                                                }}
                                            >
                                                <FaTrash style={{ marginRight: 5 }} />
                                                Delete
                                            </button>
                                            <button
                                                className="btn btn-edit"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCategory(el);
                                                    setEdit(true);
                                                }}
                                            >
                                                <FaEdit style={{ marginRight: 5 }} />
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            }
            {edit && (
                <DashboardEdit
                    datafields={{ name: "text" }}
                    datafieldsname={{ name: "Name" }}
                    apipatchendpoint={`/api/categories/${category.id}`}
                    row={category}
                    hide={() => setEdit(false)}
                />
            )
            }
        </Layout>
    );
}
