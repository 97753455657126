import React, { useState, useContext, useEffect } from "react";
import logo from "../img/logo.png";
import axios from "axios";
import { toast } from "react-toastify";
import { UserContext } from "../context/UserContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../components/Layout";

export default function Login() {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const { login, token } = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const location = useLocation();

  if (token) {
    const { from } = location.state || { from: { pathname: "/" } };
    history.replace(from);
  }

  useEffect(() => {
    document.title = "180Daraga | Login";
    if (token) history.push("/"); // if logged in redirect to home page
  }, [token, history]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    axios
      .post("/api/users/login", {
        username: form.username,
        password: form.password,
      })
      .then((response) => {
        login(response.data);
      })
      .catch((err) => {
        toast.error("Your Credentials are wrong 😔");
        setDisabled(false);
      });
  };

  return (
    <Layout className="login">
      <form onSubmit={handleSubmit}>
        <img src={logo} alt="logo" />
        <div className="input-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            className="input"
            onChange={handleChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            className="input"
            onChange={handleChange}
          />
        </div>
        <Link to="/reset" className="form-link">
          Forgot Password?
        </Link>
        <button className="btn btn-normal" disabled={disabled}>
          Login
        </button>
      </form>
    </Layout>
  );
}
