import React, { useState, useEffect, useRef } from "react";
import EventLayout from "../../components/EventLayout";
import swal from "sweetalert";
import axios from "axios";
import { useQrCode } from "../../QrCode";
import newstage17Cover from "../../img/newstage17.png";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

export default function NewStage17Feedback() {
  const [formData, setFormData] = useState({
  });

  const [disabled, setDisabled] = useState(false);
  const [qrCode, setQrCode] = useQrCode(null);
  const [showqr, setShowQR] = useState(false);
  const [likedSessions, setLikedSessions] = useState([]);
  const [dislikedSessions, setDisikedSessions] = useState([]);
  const [daysattended, setDaysAttended] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    document.title = `180Daraga | New Stage 17 Feedback`;
  });

  const headerprops = {
    maintext: "NewStage17",
    backgroundimg: newstage17Cover,
    slogan: "NewStage17 is not the End, it's Just the Beginning.",
  };

  const datafields = {
    name: "text",
    email: "text",
    phone: "text",
    days_attended: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
    session_you_liked: ["Intro To CS", "CS Department", "HR", "Software Engineeing", "DS Department",
      "Web Development", "Data Science", "Graphics", "AI", "Other Side Of Software", "DS and Entrepreneurship success story",
      "Mobile Development", "Problem Solving", "IS Department", "How TO Choose Your Carrer Path",
      "Testing", "MOOCS & Growth Mindset", "Cyper Security", "Maultinational Companies", "Version Control System",
      "Volunteering"
    ],
    session_you_disliked: ["Intro To CS", "CS Department", "HR", "Software Engineeing", "DS Department",
      "Web Development", "Data Science", "Graphics", "AI", "Other Side Of Software", "DS and Entrepreneurship success story",
      "Mobile Development", "Problem Solving", "IS Department", "How TO Choose Your Carrer Path",
      "Testing", "MOOCS & Growth Mindset", "Cyper Security", "Maultinational Companies", "Version Control System",
      "Volunteering"
    ],
    advantages: "text",
    disadvantages: "text",
    complaints: "text",
    suggestions: "text",
  };

  const datafieldsname = {
    name: "Name",
    email: "Email (optional)",
    phone: "Phone (optional)",
    days_attended: "Days You Attended",
    session_you_liked: "Sessions You Liked",
    session_you_disliked: "Sessions You Disliked",
    advantages: "Advantages",
    disadvantages: "Disadvantages",
    complaints: "Complaints",
    suggestions: "Suggestions",
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^01[0-9]{9}$/;
    return phoneRegex.test(phone);
  };

  const isFormDataValid = () => {
    if (
      !formData.name ||
      !formData.session_you_liked ||
      !formData.session_you_disliked ||
      !formData.advantages ||
      !formData.disadvantages
    ) {
      return false;
    }
    return Object.values(formData.days_attended).some((day) => day);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const handlesessionsselect = (e) => {
    const { name, value } = e.target;

    if (name === "session_you_liked") {
      setFormData({ ...formData, [name]: { sessions: value } })
      setLikedSessions(
        typeof value === 'string' ? value.split(',') : value,
      )
    }
    else if (name === "days_attended") {
      setFormData({ ...formData, [name]: { days: value } })
      setDaysAttended(
        typeof value === 'string' ? value.split(',') : value,
      )
    }
    else {
      setFormData({ ...formData, [name]: { sessions: value } })
      setDisikedSessions(
        typeof value === 'string' ? value.split(',') : value,
      )
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      days_attended: { ...prev.days_attended, [name]: checked },
    }));
    console.log(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);

    if (!isFormDataValid()) {
      swal({
        title: "OOPS!",
        text: "Please fill out all required fields and select at least one day attended.",
        icon: "error",
        button: "OK",
      });
      setDisabled(false);
      return;
    }

    if (formData.email && !isValidEmail(formData.email)) {
      swal({
        title: "Error!",
        text: "Please enter a valid email address.",
        icon: "error",
        button: "OK",
      });
      setDisabled(false);
      return;
    }

    if (formData.phone && !isValidPhone(formData.phone)) {
      swal({
        title: "Error!",
        text: "Please enter a valid phone number (01XXXXXXXXX).",
        icon: "error",
        button: "OK",
      });
      setDisabled(false);
      return;
    }
    console.log(formData);

    axios
      .post("/api/event/newstage17/feedback", formData)
      .then((response) => {
        if (response.status) {
          setShowQR(true);
          setQrCode(response.data.message);
          qrCode.append(ref.current);
        } else {
          swal({
            title: "OOPS!",
            text: "Something went wrong, please try again later.",
            icon: "error",
            button: "OK",
          });
        }
      })
      .catch((error) => {
        // console.log(error);

        swal({
          title: "Error!",
          text: error.response?.data?.message || "An error occurred.",
          icon: "error",
          button: "OK",
        });
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <>
      {!showqr && (
        <EventLayout headerconfig={headerprops} closed={false}>
          <div className="cautions">
            <h3>Give Us Your Feedback ❤️</h3>

          </div>

          <form onSubmit={handleSubmit}>
            {Object.entries(datafields).map(([key, label]) => {
              if (typeof datafields[key] === "object" && datafields[key] instanceof Array) {
                const value = key === "session_you_liked" ? likedSessions : key === "days_attended" ? daysattended : dislikedSessions;
                return (
                  <div className="input-group" key={label}>
                    <label htmlFor={key}>
                      {datafieldsname[key]}
                      <span className="required-marker">*</span>
                    </label>
                    <FormControl sx={{ m: 1, width: '100%', margin: 0, marginTop: 1 }}>
                      <InputLabel id="demo-multiple-checkbox-label">{key === "days_attended" ? "Days" : "Sessions"}</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={value}
                        name={key}
                        onChange={handlesessionsselect}
                        input={<OutlinedInput label="Sessions" />}
                        renderValue={(selected) => selected.join(', ')}
                      // MenuProps={MenuProps}
                      >
                        {datafields[key].map((name) => (
                          <MenuItem key={`${key}-${name}`} value={name}>
                            <Checkbox checked={value.includes(name)} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                );
              } else {
                return (
                  <div className="input-group" key={key}>
                    <label>{datafieldsname[key]}
                      {key === "name" ||
                        key === "suggestions" ||
                        key === "complaints" ||
                        key === "advantages" ||
                        key === "disadvantages" ? (
                        <span className="required-marker">*</span>
                      ) : null}
                    </label>
                    <input
                      type={datafields[key] || "text"}
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      style={
                        key === "complaints" ||
                          key === "advantages" ||
                          key === "disadvantages" ||
                          key === "suggestions"
                          ? { width: "100%", height: "80px" }
                          : { width: "100%" }
                      }
                      required={
                        key === "name" ||
                        key === "session_you_liked" ||
                        key === "session_you_disliked" ||
                        key === "advantages" ||
                        key === "disadvantages"
                      }
                    />
                  </div>
                );
              }
            })}
            <button className="btn btn-normal" disabled={disabled}>
              Submit
            </button>
          </form>
        </EventLayout>
      )}
      {showqr && (
        <EventLayout headerconfig={headerprops}>
          <div className="QRcodeContainer" style={{ textAlign: "center", margin: "30px" }}>
            <div className="QRcodeContainerText">
              <p style={{ fontSize: "30px", margin: "20px" }}>Thanks for your feedback.</p>
              <p style={{ fontSize: "20px", margin: "20px" }}> We reached the end of New Stage 17 but, this is just our begginning together❤️</p>
            </div>
            <div ref={ref}></div>
          </div>
        </EventLayout>
      )}
    </>
  );
}
