import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import Highboard from "../components/team/Highboard";
import PR from "../components/team/PR";
import SMM from "../components/team/SMM";
import Technical from "../components/team/Technical";
import Video from "../components/team/Video";
import Graphic from "../components/team/Graphic";
import backgroundimg from "../img/8.jpeg";

/*
import Charity from "../components/team/Charity";
import Youtube from "../components/team/Youtube";
*/

export default function Team() {
  useEffect(() => {
    document.title = "180Daraga | Team Hierarchy";
  }, []);

  const headerprops = {
    maintext: "TEAM HIERARCHY",
    backgroundimg: backgroundimg,
    slogan: "Meet our trusty masterminds behind our work!",
  };

  return (
    <Layout className="team">
      <Header {...headerprops} />
      <Highboard />
      <PR />
      <SMM />
      <Graphic />
      <Video />
      <Technical />
      {/* <Charity /> */}
      {/* <Youtube /> */}
      <Footer />
    </Layout>
  );
}
