import React from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';

export default function BlogCard(props) {
  return (
    <div class="blog_card">
      <img src={`${axios.defaults.baseURL}/public/${props.cover}`} alt="" />
      <div class="blog_content">
        <div class="text">
          <Link to={`/blogs/${props.id}`}>
            {props.title}
          </Link>
          {/* <h3>{props.title}</h3> */}
          <p className='date'>{dateFormat(Date.parse(props.date), "dddd, mmmm dS, yyyy")}</p>
          {/* <p>{props.body}</p> */}
        </div>
        {/* <div className='btn'>
          <Link to={`/blogs/${props.id}`}>
            Read More
          </Link>
        </div> */}
      </div>
    </div>
  )
}
