import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

// This component for the about section in the home page :)
export default function About() {
  const [ref, inView] = useInView({
    threshold: 0.3,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: { type: "spring", bounce: 0.4 },
      });
    } else {
      animation.start({
        x: "-100vw",
      });
    }
  }, [inView, animation]);

  return (
    <section ref={ref} className="section about">
      <motion.div animate={animation} className="container">
        <h3 className="section__heading">About Us</h3>
        <p className="section__text">
          180 Daraga is non-profit organization, founded in 2010 at Faculty of Computers and
          Information Cairo University. And in 2014 at Faculty of Computer and Information Helwan
          University. And in 2018 at Faculty of Computer and Information Ain-Shams University. It's
          an all students' movement that based on a belief in each and every individual's social
          role. Our main aim is to offer both technic and social services for faculty colleagues,
          university students and our society. 180 Daraga strives to be one of Egypt's to charitable
          organization that work in different aspects on social services setting a target of our
          country's well being.
        </p>
      </motion.div>
    </section>
  );
}
