import React , {useState} from 'react'
import { Wheel } from 'react-custom-roulette'

export default function CustomWheel({setStep , questions , setQuestion}) {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [disabled , setDisabled] = useState(false);
    const handleSpinClick = () => {
        if (!mustSpin && !disabled) {
            const rand = Math.random();
            console.log(rand)
            console.log(data.length);
            console.log(rand * data.length);
            const newPrizeNumber = Math.floor(rand * data.length);
            setPrizeNumber(newPrizeNumber);
            setQuestion(questions[newPrizeNumber]);
            setDisabled(true);
            setMustSpin(true);
        }
    }

    const handleStopSpinning = async () => {
        setMustSpin(false);
        await setTimeout(() => {
            setStep(3);
        }, 2000);
    }

    const data = questions.map((el , i) => {
        return {"option" : i + 1};
    });


    return (
        <div style={{display:'flex' , flexDirection : 'column' , justifyContent : 'center' , alignItems : 'center' , padding : "20px"}}>
            <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                outerBorderColor={["#f2f2f2"]}
                outerBorderWidth={[15]}
                innerBorderColor={["#f2f2f2"]}
                radiusLineColor={["#dedede"]}
                radiusLineWidth={[5]}
                textColors={["#ffffff"]}
                fontSize={[30]}
                perpendicularText={[true]}
                backgroundColors={[
                    "#F22B35",
                    "#F99533",
                    "#24CA69",
                    "#514E50",
                    "#46AEFF",
                    "#9145B7"
                ]}
                onStopSpinning={handleStopSpinning}
            />
            <button className='btn btn-normal' style={{width : "50%" , borderRadius : "20px" , marginTop : "10px"}} disabled={disabled} onClick={handleSpinClick}>SPIN</button>
        </div>
    )
}
