import React from "react";
import dataFields from "./data/dataFields.json";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Events from "./pages/Events";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import NotFound from "./pages/NotFound";
import Dashboard from "./admin/Dashboard";
import Users from "./admin/Users";
import AddUser from "./admin/AddUser";
import ProtectedRoute from "./components/ProtectedRoute";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence } from "framer-motion";
import Database from "./admin/Database";
import ChangePassword from "./admin/ChangePassword";
import EventMain from "./pages/events/EventMain";
import EventsList from "./pages/events/EventsList";
import EventFormDashboard from "./admin/EventFormDashboard";
import FeedbackMain from "./pages/events/FeedbackMain";
import EventsDashboard from "./admin/Events";
import AddBlog from "./admin/AddBlog";
import AddCategory from "./admin/AddCategory";
import Categories from "./admin/Categories";
import BlogsDashboard from "./admin/BlogsDashboard";
import Blogs from "./pages/BlogsMain";
import BlogPage from "./pages/BlogPage";
import EditBlog from "./admin/EditBlog";
import MailsLogs from "./admin/MailsLogs";
import NewStage16Interviews from "./admin/NewStage16Interviews";
import Recruitment23FirstInterview from "./admin/Recruitment23FirstInterview";
import MirageZone from "./pages/events/MirageZone";
import HelwanActivity from "./pages/events/HelwanActivity";
import NewStage17 from "./pages/events/NewStage17";
import NewStage17Interviews from "./admin/NewStage17Interviews";
import Recruitment24 from "./pages/events/Recruitment24";
import NewStage17Slot from "./pages/events/newstage17slot";
import NewStage17Feedback from "./pages/events/NewStage17Feedback";
import Recruitment24Interviews from "./admin/Recruitment24FirstInterview";
export default function App() {
  const location = useLocation();

  //To hide any unnoticed logs in the code, to be removed during devlemopment
  console.log = () => {
    return 1;
  };

  const AICatalystDataFields = dataFields.AICatalystDataFields;
  const AICatalystDataFieldsName = dataFields.AICatalystDataFieldsName;
  const desertMazeDataFields = dataFields.desertMazeDataFields;
  const desertMazeDataFieldsName = dataFields.desertMazeDataFieldsName;
  const pstPointsDataFields = dataFields.pstPointsDataFields;
  const newStage16DataFields = dataFields.newStage16DataFields;
  const newStage16DataFieldsName = dataFields.newStage16DataFieldsName;
  const newStage16AttendenceDataFieldsName = dataFields.newStage16AttendenceDataFieldsName;
  const newStage16FeedbackDataFields = dataFields.newStage16FeedbackDataFields;
  const newStage16FeedbackDataFieldsName = dataFields.newStage16FeedbackDataFieldsName;
  const newStage17DataFields = dataFields.newStage17DataFields;
  const newStage17DataFieldsName = dataFields.newStage17DataFieldsName;
  const newStage17AttendenceDataFieldsName = dataFields.newStage17AttendenceDataFieldsName;
  const newStage17FeedbackDataFields = dataFields.newStage17FeedbackDataFields;
  const newStage17FeedbackDataFieldsName = dataFields.newStage17FeedbackDataFieldsName;
  const recruitment23DataFields = dataFields.recruitment23DataFields;
  const recruitment23DataFieldsName = dataFields.recruitment23DataFieldsName;
  const recruitment24DataFields = dataFields.recruitment24DataFields;
  const recruitment24DataFieldsName = dataFields.recruitment24DataFieldsName;
  const startCourseDataFields = dataFields.startCourseDataFields;

  return (
    <div className="App">
      <ToastContainer />
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact component={Home} />
          <Route path="/events" exact component={Events} />
          <Route path="/team" exact component={Team} />
          <Route path="/login" exact component={Login} />
          <Route path="/blogs" exact component={Blogs} />
          <Route path="/reset" exact component={Reset} />
          <Route path="/404" exact component={NotFound} />
          <Route path="/blogs/:id" exact component={BlogPage} />
          {/* <Route path="/desertmaze" exact component={DesertMaze} /> */}
          {/* <Route path="/AICatalyst" exact component={AICatalyst} /> */}
          {/* <Route path="/newstage16" exact component={NewStage16} /> */}
          {/* <Route path="/newstage16feedback" exact component={NewStage16Feedback} /> */}
          {/* <Route path="/recruitment23" exact component={Recruitment23} /> */}
          <Route path="/miragezone" exact component={MirageZone} />
          <Route path="/helwanactivity" exact component={HelwanActivity} />
          <Route path="/newstage17" exact component={NewStage17} />
          <Route path="/recruitment24" exact component={Recruitment24} />
          <Route path="/newstage17prize" exact component={NewStage17Slot} />
          <Route path="/newstage17feedback" exact component={NewStage17Feedback} />

          {/* Dashboard Routes */}
          <ProtectedRoute path="/dashboard" exact component={Dashboard} />
          <ProtectedRoute path="/dashboard/database" exact component={Database} />
          <ProtectedRoute path="/dashboard/ChangePassword" exact component={ChangePassword} />
          <ProtectedRoute path="/dashboard/users" exact component={Users} adminOnly />
          <ProtectedRoute path="/dashboard/events" exact component={EventsDashboard} />
          <ProtectedRoute path="/dashboard/users/add" exact component={AddUser} adminOnly />
          <ProtectedRoute path="/dashboard/blogs" exact component={BlogsDashboard} smmOnly />
          <ProtectedRoute path="/dashboard/blogs/add" exact component={AddBlog} smmOnly />
          <ProtectedRoute path="/dashboard/blogs/edit/:id" exact component={EditBlog} smmOnly />
          <ProtectedRoute path="/dashboard/Categories" exact component={Categories} smmOnly />
          <ProtectedRoute path="/dashboard/Categories/add" exact component={AddCategory} smmOnly />
          <ProtectedRoute path="/dashboard/maillogs" exact component={MailsLogs} adminOnly />
          {/* Desert Maze */}
          <ProtectedRoute path={`/dashboard/desertmaze`} key="desertMaze" event_id="3-2" pmOnly>
            <EventFormDashboard
              doctitle="Desert Maze Form"
              datafields={desertMazeDataFields}
              datafieldsname={desertMazeDataFieldsName}
              api="/api/events/desertmaze/3-2"
              apipatchendpoint="/api/events/desertmaze"
              apideleteendpoint="/api/events/desertmaze"
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/attendance/desertmaze`}
            key="desertmaze3-2"
            pmOnly
            event_id="3-2"
          >
            <EventFormDashboard
              doctitle="Desert Maze Form attendance"
              datafields={desertMazeDataFields}
              datafieldsname={{ ...desertMazeDataFieldsName, date: "Attended at" }}
              eventid="3-2"
              api="/api/events/desertmaze/attendance/3-2"
            />
          </ProtectedRoute>

          {/* PST */}
          <ProtectedRoute
            path={`/dashboard/problemsolving23/points`}
            key="PST"
            event_id="2-3"
            pmOnly
          >
            <EventFormDashboard
              doctitle="PST points"
              datafields={pstPointsDataFields}
              datafieldsname={pstPointsDataFields}
              api="/api/events/eventsmain/2-3"
              apipatchendpoint="/api/events/eventsmain/all/2-3"
              apideleteendpoint="/api/events/eventsmain/2-3"
            />
          </ProtectedRoute>

          {/* AI Catalyst */}
          <ProtectedRoute path={`/dashboard/aicatalyst`} key="AICatalyst" adminOnly event_id="10-1">
            <EventFormDashboard
              doctitle="AICatalyst Form"
              datafields={AICatalystDataFields}
              datafieldsname={AICatalystDataFieldsName}
              eventid="10-1"
              api="/api/events/eventsmain/10-1"
              apipatchendpoint="/api/events/eventsmain/all/10-1"
              apideleteendpoint="/api/events/eventsmain/10-1"
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/attendance/aicatalyst`}
            key="AICatalyst"
            adminOnly
            event_id="10-1"
          >
            <EventFormDashboard
              doctitle="AICatalyst Form attendance"
              datafields={AICatalystDataFields}
              datafieldsname={{ ...AICatalystDataFieldsName, date: "Attended at" }}
              eventid="10-1"
              attendance="true"
              api="/api/events/eventsmain/attendance/10-1"
            />
          </ProtectedRoute>

          {/* Recruitment 23 */}
          <ProtectedRoute
            path={`/dashboard/recruitment23`}
            key="Recruitment23"
            event_id="8-16"
            pmOnly
          >
            <EventFormDashboard
              doctitle="Recruitment23 Form"
              datafields={recruitment23DataFields}
              datafieldsname={recruitment23DataFieldsName}
              eventid="8-16"
              api="/api/events/recruitment23"
              apipatchendpoint="/api/events/recruitment23"
              apideleteendpoint="/api/events/recruitment23"
              QRcode={true}
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/interview/recruitment23`}
            key="recruitment23Interviews"
            event_id="8-16"
            pmOnly
          >
            <Recruitment23FirstInterview />
          </ProtectedRoute>

          {/* Recruitment 24 */}
          <ProtectedRoute
            path={`/dashboard/recruitment24`}
            key="recruitment24"
            event_id="8-17"
            pmOnly
          >
            <EventFormDashboard
              doctitle="Recruitment24 Form"
              datafields={recruitment24DataFields}
              datafieldsname={recruitment24DataFieldsName}
              eventid="8-17"
              api="/api/events/recruitment24"
              apipatchendpoint="/api/events/recruitment24"
              apideleteendpoint="/api/events/recruitment24"
              QRcode={true}
              apiforgetqr="/api/events/recruitment24/sendmail/qr"
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/recruitment24interviews`}
            key="recruitment24Interviews"
            event_id="8-17"
            pmOnly
          >
            <Recruitment24Interviews />
            
          </ProtectedRoute> 

          {/* New Stage 16 */}
          <ProtectedRoute
            path={`/dashboard/interview/newstage16`}
            key="newstage16Interviews"
            event_id="5-16"
            pmOnly
          >
            <NewStage16Interviews />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/feedback/newstage16`}
            key="newstagefeedback"
            event_id="5-16"
            pmOnly
          >
            <EventFormDashboard
              doctitle="New Stage Feedback"
              datafields={newStage16FeedbackDataFields}
              datafieldsname={newStage16FeedbackDataFieldsName}
              eventid="5-16"
              api="/api/events/newStage16/all/5-16/feedback"
            />
          </ProtectedRoute>
          <ProtectedRoute path={`/dashboard/newstage16`} key="newstage16" pmOnly event_id="5-16">
            <EventFormDashboard
              doctitle="New Stage 16"
              datafields={newStage16DataFields}
              datafieldsname={newStage16DataFieldsName}
              eventid="5-16"
              api="/api/events/newStage16/all/5-16"
              apipatchendpoint="/api/events/newStage16/5-16"
              apideleteendpoint="/api/events/newStage16/5-16"
              QRcode={true}
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/attendance/newstage16`}
            key="newstage5-16"
            event_id="5-16"
            pmOnly
          >
            <EventFormDashboard
              doctitle="New Stage Form attendance"
              datafields={newStage16DataFields}
              datafieldsname={newStage16AttendenceDataFieldsName}
              eventid="5-16"
              api="/api/events/newStage16/attendance/5-16"
            />
          </ProtectedRoute>

          {/* New Stage 17 */}
          <ProtectedRoute path={`/dashboard/newstage17`} key="newstage17" event_id="5-17" pmOnly>
            <EventFormDashboard
              doctitle="New Stage 17"
              datafields={newStage17DataFields}
              datafieldsname={newStage17DataFieldsName}
              eventid="5-17"
              api="/api/event/newstage17"
              apipatchendpoint="/api/event/newstage17"
              apideleteendpoint="/api/event/newstage17"
              QRcode={true}
              apiforgetqr="/api/event/newstage17/sendmail/qr"
            />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/newstage17interviews`}
            key="newstage17Interviews"
            event_id="5-17"
            pmOnly
          >
            <NewStage17Interviews />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/newstage17attendance`}
            key="newstage17attendance"
            event_id="5-17"
            pmOnly
          >
            <EventFormDashboard
              doctitle="New Stage17 Attendance"
              datafields={newStage17DataFields}
              datafieldsname={newStage17AttendenceDataFieldsName}
              eventid="5-17"
              api="/api/event/newstage17/attendance"
            />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/newstage17feedbacks`}
            key="newstage17feedbacks"
            event_id="5-17"
            pmOnly
          >
            <EventFormDashboard
              doctitle="New Stage 17 Feedbacks"
              datafields={newStage17FeedbackDataFields}
              datafieldsname={newStage17FeedbackDataFieldsName}
              eventid="5-17"
              api="/api/event/newstage17/feedback"
            />
          </ProtectedRoute>

          {/* Start Course 24 */}
          <ProtectedRoute
            path={`/dashboard/startcourse24/score`}
            key="startcourse24"
            event_id="7-12"
            pmOnly
          >
            <EventFormDashboard
              doctitle="Start Course Scores"
              datafields={startCourseDataFields}
              datafieldsname={startCourseDataFields}
              api="/api/events/eventsmain/7-12"
              apipatchendpoint="/api/events/eventsmain/all/7-12"
              apideleteendpoint="/api/events/eventsmain/7-12"
            />
          </ProtectedRoute>

          {EventsList.map((e, key) => {
            return e.protected ? (
              <ProtectedRoute path={`${e.path}`} exact key={key}>
                <EventMain
                  doctitle={e.doctitle}
                  headerconfig={e.headerconfig}
                  datafields={e.datafields}
                  datafieldsname={e.datafieldsname}
                  datafieldsdep={e.datafieldsdep}
                  eventid={e.eventid}
                  api={e.apipostendpoint}
                  QRcode={e.QRcode}
                  cautions={e.cautions}
                  sendMail={e.sendMail}
                  updateForm={e.updateForm}
                  constraint={e.constraint}
                />
              </ProtectedRoute>
            ) : (
              <Route path={`${e.path}`} exact key={key}>
                <EventMain
                  closed={e.closed}
                  doctitle={e.doctitle}
                  headerconfig={e.headerconfig}
                  datafields={e.datafields}
                  datafieldsname={e.datafieldsname}
                  datafieldsdep={e.datafieldsdep}
                  eventid={e.eventid}
                  api={e.apipostendpoint}
                  QRcode={e.QRcode}
                  cautions={e.cautions}
                  sendMail={e.sendMail}
                  updateForm={e.updateForm}
                  constraint={e.constraint}
                  noRepeat={e.noRepeat}
                />
              </Route>
            );
          })}

          {EventsList.map((e, key) => {
            if (!e.updateForm)
              if (e.charity)
                return (
                  <ProtectedRoute path={`/dashboard${e.path}`} key={key} charityOnly>
                    <EventFormDashboard
                      doctitle={e.doctitle}
                      datafields={e.datafields}
                      datafieldsname={e.datafieldsname}
                      eventid={e.eventid}
                      api={e.apigetendpoint}
                      apipatchendpoint={e.apipatchendpoint}
                      apideleteendpoint={e.apideleteendpoint}
                    />
                  </ProtectedRoute>
                );
              else
                return (
                  <ProtectedRoute
                    path={`/dashboard${e.path}`}
                    key={key}
                    pmOnly
                    event_id={e.eventid}
                  >
                    <EventFormDashboard
                      doctitle={e.doctitle}
                      datafields={e.datafields}
                      datafieldsname={e.datafieldsname}
                      eventid={e.eventid}
                      api={e.apigetendpoint}
                      apipatchendpoint={e.apipatchendpoint}
                      apideleteendpoint={e.apideleteendpoint}
                      QRcode={e.QRcode}
                    />
                  </ProtectedRoute>
                );
                return null;
          })}

          {EventsList.map((e, key) => {
            const feedbackheader = {
              ...e.headerconfig,
              maintext: "Feedback",
              slogan: e.headerconfig.maintext + " feedback!",
            };
            return e.feedback ? (
              <Route path={`${e.path}/feedback`} key={key}>
                <FeedbackMain
                  headerconfig={feedbackheader}
                  doctitle={e.feedbackconfig.doctitle}
                  datafields={e.feedbackconfig.datafields}
                  datafieldsname={e.feedbackconfig.datafieldsname}
                  cautions={e.feedbackconfig.feedbackcautions}
                  api={e.feedbackconfig.feedbackapipostendpoint}
                />
              </Route>
            ) : null;
          })}

          {EventsList.map((e, key) => {
            if (e.attendance)
              return (
                <ProtectedRoute
                  path={`/dashboard/attendance${e.path}`}
                  key={key}
                  pmOnly
                  event_id={e.eventid}
                >
                  <EventFormDashboard
                    doctitle={e.doctitle + ` attendance`}
                    datafields={e.datafields}
                    datafieldsname={e.attendancedatafieldsname}
                    eventid={e.eventid}
                    api={e.apiattendancegetendpoint}
                    attendance={e.attendance}
                  />
                </ProtectedRoute>
              );
              return null;
          })}

          {EventsList.map((e, key) => {
            if (e.feedback)
              return (
                <ProtectedRoute path={`/dashboard/feedback${e.path}`} key={key}>
                  <EventFormDashboard
                    doctitle={e.feedbackconfig.doctitle}
                    datafields={e.feedbackconfig.datafields}
                    datafieldsname={e.feedbackconfig.datafieldsname}
                    eventid={e.eventid}
                    api={e.feedbackconfig.feedbackapigetendpoint}
                  />
                </ProtectedRoute>
              );
              return null;
          })}

          <Redirect to="/404" />
        </Switch>
      </AnimatePresence>
    </div>
  );
}