import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Layout from "../components/Layout";

import backgroundimg from "../img/1.jpeg";

export default function Events() {
  useEffect(() => {
    document.title = "180Daraga | Events";
  }, []);

  const headerprops = {
    maintext: "OUR EVENTS",
    backgroundimg: backgroundimg,
    slogan: "The whole timeline of 180 Daraga's event across the years!",
  };

  return (
    <Layout className="events">
      <Header {...headerprops} />
      <section className="section timeline">
        <div className="container">
          {/*<h3 className="section__heading">The Timeline For All Our Events</h3>*/}
          <div className="timeline-container left">
            <div className="content">
              <h4>2024</h4>
              <ul>
                <li>Start Course'23</li>
                <li>Recruitment'23</li>
                <li>غِطاء ٨</li>
                <li>من رمضان للعيد 8</li>
                <li>فينا خير 9</li>
                <li>Juniors Journey 3</li>
                <li>New Stage 17</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container right">
            <div className="content">
              <h4>2023</h4>
              <ul>
                <li>من رمضان للعيد 7</li>
                <li>The Camp</li>
                <li>Desert Maze</li>
                <li>غِطاء 7</li>
                <li>The Camp</li>
                <li>من رمضان للعيد 7</li>
                <li>FCI Mystery</li>
                <li>فينا خير 8</li>
                <li>Problem Solving Training</li>
                <li>Summer Camp'23</li>
                <li>Juniors Journey 2</li>
                <li>New Stage 16</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container left">
            <div className="content">
              <h4>2022</h4>
              <ul>
                <li>Start Course'22</li>
                <li>Recruitment'22</li>
                <li>Summer Camp'22</li>
                <li>New Stage 15</li>
                <li>Juniors Journey</li>
                <li>Problem Solving Training 2 (level 1)</li>
                <li>فينا خير 7</li>
                <li>من رمضان للعيد 6</li>
                <li>Problem Solving Training 2 (level 0)</li>
                <li>Slash-Back</li>
                <li>The Camp</li>
                <li>غِطاء 6</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container right">
            <div className="content">
              <h4>2021</h4>
              <ul>
                <li>Problem Solving Training</li>
                <li>The Camp - Round 1</li>
                <li>غِطاء 5</li>
                <li>The Camp - Round 2</li>
                <li>من رمضان للعيد 5</li>
                <li>Problem Solving Traning Level 1</li>
                <li>فينا خير 6</li>
                <li>New Stage 14</li>
                <li>Summer Camp</li>
                <li>Recruitment'21</li>
                <li>Start Course'21</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container left">
            <div className="content">
              <h4>2020</h4>
              <ul>
                <li>غِطاء 4</li>
                <li>The Compass</li>
                <li>180Daraga The Meetups</li>
                <li>The Camp</li>
                <li>Summer Camp</li>
                <li>New Stage 13</li>
                <li>Start Course'20</li>
                <li>Recruitment'20</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container right">
            <div className="content">
              <h4>2019</h4>
              <ul>
                <li>Mid Year Courses</li>
                <li>IBM Digital Nation Africa Innovation Tour by IBM & 180 Daraga</li>
                <li>Shortcut 2</li>
                <li>Workzone 3</li>
                <li>Photoday</li>
                <li>من رمضان للعيد 4</li>
                <li>فينا خير 5 </li>
                <li>Summer Courses</li>
                <li>New Stage 12</li>
                <li>Recruitment</li>
                <li>Start Course'19</li>
                <li>Cairo University Cybersecurity CTF</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container left">
            <div className="content">
              <h4>2018</h4>
              <ul>
                <li>Mid Year Courses</li>
                <li>Talents 404</li>
                <li>Workzone 2</li>
                <li>Dot EXE2</li>
                <li>Photoday</li>
                <li>Summer Courses</li>
                <li>New Stage 11</li>
                <li>Sporting Day (Big Bang 2)</li>
                <li>Recruitment</li>
                <li>Start Course</li>
                <li>غِطاء 3</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container right">
            <div className="content">
              <h4>2017</h4>
              <ul>
                <li>Mid Year Courses</li>
                <li>6Geeks</li>
                <li>Shortcut 1</li>
                <li>Graduation Ceremony</li>
                <li>Department Day</li>
                <li>Graduation Project Day</li>
                <li>Photoday</li>
                <li>من رمضان للعيد 2 </li>
                <li>فينا خير3</li>
                <li>Summer Courses</li>
                <li>New Stage 10</li>
                <li>Start Course</li>
                <li>غِطاء 2</li>
                <li>F5</li>
                <li>Markedia</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container left">
            <div className="content">
              <h4>2016</h4>
              <ul>
                <li>Mid Year Courses</li>
                <li>إبتديها خير (فينا خير )</li>
                <li>Graduaton Ceremony</li>
                <li>Workzone 1</li>
                <li>Department Day</li>
                <li>Graduation Project Day</li>
                <li>Photoday</li>
                <li>من رمضان للعيد 1 </li>
                <li>فينا خير 2</li>
                <li>New Stage 9</li>
                <li>Recruitment</li>
                <li>Start Course</li>
                <li>غِطاء</li>
                <li>Dot EXE 1</li>
                <li>F5</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container right">
            <div className="content">
              <h4>2015</h4>
              <ul>
                <li>FCIH Opening</li>
                <li>F5</li>
                <li>Mid Year Courses</li>
                <li>Photoday</li>
                <li>Summer Courses</li>
                <li>New Stage 8</li>
                <li>فينا خير 2</li>
                <li>Recruitment</li>
                <li>Start Course</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container left">
            <div className="content">
              <h4>2014</h4>
              <ul>
                <li>Recruitment</li>
                <li>Mid Year Courses</li>
                <li>Department Day</li>
                <li>Summer Courses</li>
                <li>New Stage 7</li>
                <li>خير مع الغير</li>
                <li>Start Course</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container right">
            <div className="content">
              <h4>2013</h4>
              <ul>
                <li>Recruitment</li>
                <li>Winter Charity</li>
                <li>Technolobia</li>
                <li>FCI City Birthday</li>
                <li>فن الفشل</li>
                <li>Photoday</li>
                <li>New Stage 6</li>
                <li>Start Course</li>
                <li>فينا خير واحد</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container left">
            <div className="content">
              <h4>2012</h4>
              <ul>
                <li>FCI City Birthday</li>
                <li>C++ Course</li>
                <li>Mid Year Courses</li>
                <li>Wadi Elrayan Outing</li>
                <li>F5</li>
                <li>Dream Park Event</li>
                <li>Sporting Day</li>
                <li>Department Day</li>
                <li>Photoday</li>
                <li>Ramadan Charity</li>
                <li>كساء</li>
                <li>57357 Day</li>
                <li>فن الفشل</li>
                <li>New Stage 5</li>
                <li>Graduation Prom</li>
                <li>Summer Courses</li>
                <li>Recruitment</li>
                <li>12-12-12 Event</li>
                <li>Handmade Gallery</li>
              </ul>
            </div>
          </div>
          <div className="timeline-container right">
            <div className="content">
              <h4>2011</h4>
              <ul>
                <li>New Stage 4</li>
                <li>Electro Study</li>
                <li>Summer Coures</li>
                <li>Recruitment</li>
                <li>Sporting Day</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
}
