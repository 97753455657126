import React, { useState } from 'react'
import Countdown from 'react-countdown';
export default function Questions({ question, setStep, setStatus }) {
    const [choiceIndex, setChoiceIndex] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (choiceIndex !== null) {
            setStatus(question.rightChoiceIndex === choiceIndex ? "A" : "R")
            setStep(4);
        }

    }
    const renderer = ({ hours, minutes, seconds, completed }) => {

        if (completed)
            setStep(4);

        return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h1>الوقت المتبقي</h1>
            <h1 style={{ fontSize: "20px" , border : "black 2px solid" , borderRadius : "100%" , padding : "10px"}}>{minutes * 60 + seconds}</h1>
        </div>;

    };

    return (
        <div style={{ direction: "rtl", display: "flex", justifyContent: "center", alignItems: "center", padding: "20px", flexDirection: "column", gap: "40px" }}>
            <Countdown renderer={renderer} daysInHours={true} date={Date.now() + 60000} />
            <div style={{display : "flex" , justifyContent : "center" , alignItems : "center" , flexDirection : "column" , gap : "20px"}}>
                <h3 style={{ fontSize: "22px" }}>{question.title}</h3>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                    {
                        question.choices.map((el, i) => {
                            return (
                                <div key={i} style={{ cursor: "pointer", padding: "10px 20px", width: "100%", maxWidth: "400px", backgroundColor: choiceIndex === i ? "#1f1f1f" : "#700607", color: "white", textAlign: "center", borderRadius: "20px" }} onClick={() => setChoiceIndex(i)}>
                                    {el}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <button className="btn btn-normal" onClick={handleSubmit}>
                submit
            </button>
        </div>
    )
}
