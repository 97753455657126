import React from "react";
import Mohsen from "../../img/board2025/Mohamed Mohsen.jpg";
import Ayman from "../../img/board2025/Ayman Abokhalifa.JPG";
import Shrouk from "../../img/board2025/Shrouk Ezzat.jpg";

export default function PR() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>PR Committee</h2>
        <div>
            <img src={Mohsen} alt="" />
            <h3>Mohamed Mohsen</h3>
            <p>committee head</p>
          </div>
        <div className="supervisors">
          
          <div>
            <img src={Ayman} alt="" />
            <h3>Ayman Abo Khalifa</h3>
            <p>Vice committee head</p>
          </div>
          <div>
            <img src={Shrouk} alt="" />
            <h3>Shrouk Ezzat</h3>
            <p>Vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
