import React, { useState } from "react";
import Layout from "./components/Layout";
//import { useContext } from "react";
//import { UserContext } from "../context/UserContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { FaPlus } from "react-icons/fa";
import axios from "axios";

export default function AddUser() {
  const [disableBtn, setDisbale] = useState(false);
  //const { token } = useContext(UserContext);
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    username: "",
    email: "",
    password: "",
    role: "",
    event_id: ""
  });
  const history = useHistory();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisbale(true);
    axios
      .post(`/api/users`, { ...form })
      .then((response) => {
        setDisbale(false);
        history.push("/dashboard/users");
      })
      .catch((err) => {
        setDisbale(false);
        toast.error("Error 😔");
      });
  };
  return (
    <Layout>
      <h2 className="title">Add New User</h2>
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label>First Name</label>
          <input type="text" name="fname" value={form.fname} required onChange={handleChange} />
        </div>
        <div className="input-group">
          <label>Last Name</label>
          <input type="text" name="lname" value={form.lname} required onChange={handleChange} />
        </div>
        <div className="input-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={form.username}
            required
            onChange={handleChange}
          />
        </div>
        <div className="input-group">
          <label>Email</label>
          <input type="text" name="email" value={form.email} required onChange={handleChange} />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={form.password}
            required
            onChange={handleChange}
          />
        </div>
        <div className="input-group">
          <label>Role</label>
          <select name="role" value={form.role} required onChange={handleChange}>
            <option value="" defaultValue>
              Choose Role
            </option>
            <option value="admin">Admin</option>
            <option value="pm">PM</option>
            <option value="smm">SMM</option>
            <option value="pr">PR</option>
            <option value="technical">Technical</option>
            <option value="charity">Charity</option>
          </select>
        </div>
        {
          (form.role === "pm" || form.role === "technical") && (
            <div className="input-group">
              <label>Event ID</label>
              <input
                type="text"
                name="event_id"
                value={form.event_id}
                required
                onChange={handleChange}
              />
            </div>
          )
        }
        <button className="btn btn-normal" disabled={disableBtn}>
          <FaPlus style={{ marginRight: 5 }} />
          Add User
        </button>
      </form>
    </Layout>
  );
}
