import axios from "axios";
import Layout from "./components/Layout";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { FaPlus, FaTrash, FaKey, FaUserEdit } from "react-icons/fa";
import genPassword from "generate-password";
import { UserContext } from "../context/UserContext";
import DashboardEdit from "./components/DashboardEdit"

export default function Users() {
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const { id } = useContext(UserContext);
  const [user, setUser] = useState([]);

  const getUsers = () => {
    axios
      .get(`/api/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err) => toast.error(err));
  };

  useEffect(getUsers, []);

  const deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover the user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! User has been deleted!", {
          icon: "success",
        });
        axios
          .delete(`/api/users/${id}`)
          .then((response) => {
            getUsers();
          })
          .then((response) => {
            console.log(response);
          })
          .catch((err) => toast.error("Error 😔"));
      } else {
        swal("User is safe!");
      }
    });
  };

  const updatePassword = (id) => {
    const newPassword = genPassword.generate({
      length: 15,
      numbers: true,
      symbols: false,
    });

    axios
      .patch(`/api/users/updatepass`, {
        id: id,
        password: newPassword,
      })
      .then((response) => {
        swal("Password Updated!", `New Password: ${newPassword}`, "success");
      })
      .catch((err) => toast.error("Error 😔"));
  };
  return (
    <Layout>
      {
        !edit && (
          <div className="table">
            <h2 className="title">Users Table</h2>
            <Link to="/dashboard/users/add" className="btn btn-success">
              <FaPlus style={{ marginRight: 5 }} />
              Add New
            </Link>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Fname</th>
                  <th>Lname</th>
                  <th>Username</th>
                  <th>Role</th>
                  <th>Control</th>
                </tr>
              </thead>
              <tbody>
                {users.map((el) => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.fname}</td>
                    <td>{el.lname}</td>
                    <td>{el.username}</td>
                    <td>{`${el.role}${el.role === "pm" || el.role === "technical"? ` & ${el.event_id}` : ""}`}</td>
                    <td>
                      {id !== el.id && (
                        <button
                          className="btn btn-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteUser(el.id);
                          }}
                        >
                          <FaTrash style={{ marginRight: 5 }} />
                          Delete
                        </button>
                      )}
                      <button
                        className="btn btn-info"
                        onClick={(e) => {
                          e.preventDefault();
                          updatePassword(el.id);
                        }}
                      >
                        <FaKey style={{ marginRight: 5 }} />
                        Generate New Password
                      </button>
                      <button
                        className="btn btn-edit"
                        onClick={(e) => {
                          e.preventDefault();
                          setUser(el);
                          setEdit(true);
                        }}
                      >
                        <FaUserEdit style={{ marginRight: 5 }} />
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      }
      {edit && (
        <DashboardEdit
          datafields={{ username: "text", email: "email", role: ["admin", "pm", "technical", "smm", "pr"], event_id: "text" }}
          datafieldsname={{ username: "Username", email: "Email", role: "Role", event_id: "Event Id" }}
          apipatchendpoint={`/api/users/updateuser`}
          row={user}
          hide={() => setEdit(false)}
        />
      )
      }
    </Layout>
  );
}
